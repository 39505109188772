import * as yup from 'yup'

export const validateInput = (key, fields, errors, schema) => {
    const { errors: vErrors } = schema(fields)
    return { ...errors, [key]: vErrors[key] }
}

export const validateSchema = (data, schema) => {
    try {
        schema.validateSync(data, { abortEarly: false, allowUnknown: true })
    return {
      errors: {},
      isValid: true,
    }
  } catch (error) {
    const errors = error.inner.reduce((acc, el) => {
      const { path, message } = el
      acc[path] = message.replace(path, 'this')
      return acc
    }, {})
    return { isValid: false, errors }
  }
}

const createRequestScheme = yup.object().shape({
  name: yup.string().required(),
  description: yup.string()
})

export const createRequestValidation = data => {
    return validateSchema(data, createRequestScheme)
}

export const createRequestInputValidation = (key, fields, errors) => {
    return validateInput(key, fields, errors, createRequestValidation)
}

const createAddFolderScheme = yup.object().shape({
  name: yup.string().required()
})

export const createAddFolderValidation = data => {
  return validateSchema(data, createAddFolderScheme)
}

export const createAddFolderInputValidation = (key, fields, errors) => {
  return validateInput(key, fields, errors, createAddFolderValidation)
}

const RequestDateScheme = yup.object().shape({
  date_receive: yup.string().required()
})

export const requestDateSchemeValidation = data => {
    return validateSchema(data, RequestDateScheme)
}

export const RequestReviewLinkScheme = yup.object().shape({
  review_link: yup.string().required()
})

export const requestReviewLinkSchemeValidation = data => {
  return validateSchema(data, RequestReviewLinkScheme)
}

export const requestDateSchemeInputValidation = (key, fields, errors) => {
    return validateInput(key, fields, errors, createRequestValidation)
}

export function requestDateInitialState() {
  return {
    showModal: false,
    showModalReviewLink: false,
    statusHighlight: false,
    refFields: [],
    fields: {
      date_receive: "",
      review_link: "",
    },
    errors: {
      date_receive: "",
      review_link: "",
    },
  }
}

export const columns = [
    {
        name: 'Request Name',
        key: 'name',
        sort: true,
    },
    {
        name: 'Status',
        key: 'request_status',
        sort: true,
    },
    {
        name: 'Requested By',
        key: 'request_by',
        sort: true,
    },
    {
        name: 'Requested Date',
        key: 'created_date',
        sort: true,
    },
    {
        name: 'Members',
        key: 'collaborators',
        sort: true,
    }
]

export const logs_columns = [
  {
      name: 'Request By',
      key: 'requested_by',
      sort: false,
  },
  {
      name: 'From',
      key: 'from',
      sort: false,
  },
  {
      name: 'To',
      key: 'to',
      sort: false,
  },
  {
    name: 'Status',
    key: 'status',
    sort: false,
  },
  {
      name: 'Requested Date',
      key: 'created_date',
      sort: false,
  },
  
]

export function requestInitialState() {
  return {
    showSection: false,
    hasChanges: false,
    answers: [],
    refAnswers: [],
    refFields: {},
    fields: {
      name: "",
      description: "",
    },
    errors: {
      name: "",
      description: "",
    },
  }
}

export const requestStatusList = [
    {
        name: 'Submitted',
        role: ['Admin', 'User', 'Editor', 'Creative Director'],
        enableWhen: ['Submitted']
    },
    {
        name: 'Assigned',
        role: ['Admin'],
        enableWhen: ['Submitted', 'Amendments needed']
    },
    {
        name: 'In progress',
        // role: ['Admin', 'Editor'],
        role: ['Editor'],
        enableWhen: ['Assigned', 'Amendments needed']
    },
    {
      name: 'Storyboard - In progress',
      role: ['Creative Director'],
      enableWhen: ['Assigned', 'Amendments needed']
    },
    {
        name: 'Ready for review',
         role: ['Admin', 'Editor', 'Creative Director'],
        //role: ['Editor'],
        enableWhen: ['In progress', 'Storyboard - In progress']
    },
    {
        name: 'Sent for review',
        role: ['Admin'],
        enableWhen: ['Ready for review']
    },
    {
        name: 'Amendments needed',
        role: ['Admin', 'User'],
        enableWhen: ['Sent for review', 'Latest version ready']
    },
    {
      name: 'Latest version ready',
      role: ['Admin'],
      enableWhen: ['Sent for review', 'Amendments needed']
    },
    {
        name: 'Approved',
        role: ['Admin', 'User'],
        enableWhen: ['Latest version ready', 'Sent for review', 'Amendments needed']
    }
]

export const deliverableColumns = [
  {
    name: 'Created Date',
    key: 'created_date',
    sort: true,
    width: '12%'
  },
  {
    name: 'Platform',
    key: 'platform',
    sort: true,
  },
  {
    name: 'Placement',
    key: 'placement',
    sort: true,
    width: '20%'
  },
  {
		name: 'Type',
		key: 'type',
		sort: true,
    width: '10%'
	},
  {
		name: 'Length',
		key: 'length',
		sort: true,
    width: '10%'
	},
  {
		name: 'Dimension',
		key: 'dimension',
		sort: true,
    width: '12%'
	},
  {
		name: 'Review',
		key: 'review',
		sort: false,
    width: '10%'
	},
  {
    name: 'Status',
    key: 'status',
    sort: false,
    width: '20%'
  }
]

export const requestStatusListV3 = [
  {
      name: 'Submitted',
      role: ['Admin']
  },
  {
      name: 'Assigned',
      role: ['Admin']
  },
  {
    name: 'In progress',
    role: ['Admin']
  },
  {
      name: 'Approved',
      role: ['Admin']
  }
]