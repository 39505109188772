<template>
    <div>
        <modal :show="showModal" @close="handleCloseDialog">
            <h3 slot="header" class="modal-title" id="modal-title-request-receive-date">Receive Date</h3>
            <base-input
                type="date"
                required
                :value="$moment(fields.date_receive).format('YYYY-MM-DD')"
                :error="errors.date_receive"
                :valid="!errors.date_receive ? true : false"
                @input="handleDateChange"
            />
            <template slot="footer">
                <base-button type="link" @click="handleCloseDialog">Close</base-button>
                <base-button type="primary" @click.prevent="handleUpdateDate">Save changes</base-button>
            </template>
        </modal>
        <modal :show="showModalReviewLink" @close="handleReviewLinkCloseDialog">
            <h3 slot="header" class="modal-title" id="modal-title-request-receive-date">Review Link</h3>
            <base-input
                type="text"
                required
                :value="fields.review_link"
                :error="errors.review_link"
                :valid="!errors.review_link ? true : false"
                 @input="handleReviewLinkChange"
            />
            <loader :loading="loading" />
            <template slot="footer" v-if="!loading">
                <base-button type="link" @click="handleReviewLinkCloseDialog">Close</base-button>
                <base-button type="primary" @click.prevent="handleReviewLinkUpdate">Save changes</base-button>
            </template>
        </modal>
        <div class="card shadow status-bar-container" id="status-bar-container">
            <div class="card-body">
                <div class="row status-bar">
                    <div class="col-lg-3 col-md-6 col-xs-6 status-item">
                        <div class="status-details">
                            <div class="status-title">Request Date:</div>
                            <div class="status-value">{{ getFormattedDate('created_date') }}</div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-xs-6 status-item">
                        <div class="status-details">
                            <div class="status-title">Receive by:</div>
                            <div class="status-value">{{ getFormattedDate('date_receive') }}</div>
                        </div>
                        <base-button
                            v-if="isAdmin"
                            type="neutral"
                            rounded
                            @click="handleOpenDialog"
                            class="btn-edit-status-date"
                        >
                            <img src="/img/icons/common/edit.svg" class="image-icon" />
                        </base-button>
                    </div>
                    <div class="col-lg-3 col-md-6 col-xs-6 status-item">
                        <div class="status-details">
                            <div class="status-title">Last update:</div>
                            <div class="status-value">{{ getFormattedDate('updated_date') }}</div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-xs-6 status-item" :class="{highlight: statusHighlight}" ref='status-update'>
                        

                        <div class="status-details">
                            <div class="status-title">Status:</div>
                            <div class="status-value" v-if="loading"><loader :loading="loading" size="15px" /></div>
                            <div class="status-value" v-if="!loading">{{ requestStatus }}</div>
                        </div>
                        <base-dropdown
                            v-if="(isAdmin || isEditor || isCreativeDirector) && !loading || (isUser && (requestStatus === 'Sent for review' || requestStatus === 'Latest version ready'))"
                            class="dropdown"
                            position="right"
                        >
                            <a
                                slot="title"
                                class="btn btn-edit-status btn-neutral btn-sm"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <img src="/img/icons/common/status-arrow-bottom.svg" class="image-icon" />
                            </a>
                            <template>
                                <a
                                    class="dropdown-item"
                                    href="#"
                                    v-for="(status, key) in getStatusList" :key="key"
                                    :class="{
                                        active: requestStatus === status.name,
                                        disabled: !isAdmin && !status.enableWhen.includes(requestStatus)
                                    }"
                                    :disabled="!status.enableWhen.includes(requestStatus)"
                                    @click="handleUpdateStatus(status)"
                                >
                                    {{ status.name }}
                                </a>
                            </template>
                        </base-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex"
    import {
        requestStatusList,
        requestDateInitialState,
        requestDateSchemeValidation,
        requestDateSchemeInputValidation,
        requestReviewLinkSchemeValidation,
    } from "@/container/request-details/model";
    import NOTIFICATION_TITLE from '@/utils/constant'

    export default {
        name: 'request-status-section',
        components: {},
        data: () => requestDateInitialState(),
        methods: {
            validate(field) {
                this.errors = requestDateSchemeInputValidation(field, this.fields, this.errors);
            },
            getFormattedDate(key) {
                if (!this.data || (!this.data[key]))
                    return 'N/A'

                return this.$moment(this.data[key]).format('L')
            },
            handleOpenDialog() {
                this.fields.date_receive = this.refFields.date_receive
                this.showModal = true
            },
            handleReviewLinkCloseDialog() {
                this.showModalReviewLink = false
            },
            handleCloseDialog() {
                this.showModal = false
            },
            handleDateChange(value) {
                this.fields.date_receive = value
                this.validate('date_receive')
            },
            handleReviewLinkChange(value) {
                this.fields.review_link = value
                this.validate('review_link')
            },
            async handleUpdateStatus(status) {
                if (!this.isAdmin && !status.enableWhen.includes(this.requestStatus))
                    return

                if(status.name === 'Latest version ready') {
                    this.showModalReviewLink = true;
                    return
                }

                const request = await this.$store.dispatch("REQUEST/updateRequestStatus", {
                    id: this.data.id,
                    status: status.name
                })

                if (request) {
                   this.notifyUsers(request)
                } 

                this.handleGetDetails()
                this.$root.$refs.RequestLogs.handleTableChange();
            },
            async handleGetDetails() {
                if (this.requestId == 'new')
                    return

                await this.$store
                    .dispatch('REQUEST/getRequestData', this.requestId)
                    .catch(() => this.$router.push({ name: 'request'}))
            },
            async handleReviewLinkUpdate() {
                if (this.$lodash.lowerCase(this.requestId) !== 'new' && !this.isAdmin)
                    return
                
                const { isValid, errors } = requestReviewLinkSchemeValidation(this.fields)
            
                if (!isValid)
                    return (this.errors = errors)
                
                const request = await this.$store.dispatch("REQUEST/updateRequestStatus", {
                    id: this.data.id,
                    status: 'Latest version ready',
                    review_link: this.fields.review_link
                })
              
                this.handleReviewLinkCloseDialog()
                console.log("call comment function");
                this.$root.$refs.A.getComments()
                if (request) {
                   this.notifyUsers(request)
                } 

                this.handleGetDetails()                
            },
            async handleUpdateDate() {
                // make sure only admin can edit
                if (this.$lodash.lowerCase(this.requestId) !== 'new' && !this.isAdmin)
                    return

                const { isValid, errors } = requestDateSchemeValidation(this.fields)

                if (!isValid)
                    return (this.errors = errors)

                const request = await this.$store.dispatch("REQUEST/updateReceiveDate", {
                    id: this.data.id,
                    ...this.fields,
                })

                if (request) {
                    this.handleGetDetails()
                    this.handleCloseDialog()
                    this.$notify({
                        icon: "ni ni-check-bold",
                        type: "success",
                        title: "Success",
                        message: `Request successfully ${this.requestId === 'new'?'created':'updated'}!`,
                    })
                } else {
                    // this.$notify({
                    //     icon: "fa fa-exclamation-triangle",
                    //     type: "danger",
                    //     title: "Error",
                    //     message: `Something went wrong!`,
                    // })
                }
            },
            notifyUsers(request) {
                this.$notify({
                    icon: "ni ni-check-bold",
                    type: "success",
                    title: "Success",
                    message: `Request status successfully updated!`,
                })
                console.log('request', request)

                const response = request;
                const internal_client = request?.internal_client;

                if(internal_client) {
                    if(response?.request_status === 'Amendments needed') {
                        const amemdments = this.data.collaborators.map(each => each.role == 'Editor' ?  each.id : false) 
                        const internals = internal_client.user_ids.map(id => id != this.userId ? id : false)
                        const to_notify = [...new Set([ ...amemdments, ...internals])]
                        console.log('notify', to_notify)
                        to_notify.forEach(d => {
                            if(d) {
                                this.$socket.send(JSON.stringify({
                                    recipient: d,
                                    title: NOTIFICATION_TITLE.STATUS_UPDATE,
                                    message: `${this.data.name} status has been reviewed and ready amendments`
                                }));
                            }
                        });
                    }
    
                    if(response.request_status === 'Latest version ready') {
                        const amemdments = this.data.collaborators.map(each => each.role == 'Editor' ?  each.id : false) 
                        const internals = internal_client.user_ids.map(id => id != this.userId ? id : false)
                        const to_notify = [...new Set([ ...amemdments, ...internals])]
                        console.log('notify', to_notify)
                        to_notify.forEach(d => {
                            if(d) {
                                this.$socket.send(JSON.stringify({
                                    recipient: d,
                                    title: NOTIFICATION_TITLE.STATUS_UPDATE,
                                    message: `${this.data.name} has now Latest version ready`
                                }));
                                
                            }
                        });
                        
                    }
                }

                if(response.request_status === 'Sent for review') {
                    this.data.collaborators.forEach(d => {
                        if((d.role != "Editor" || d.role != 'Creative Director') && d.id != this.userId) {
                            this.$socket.send(JSON.stringify({
                                recipient: d.id,
                                title: NOTIFICATION_TITLE.STATUS_UPDATE,
                                message: `${this.data.name} is now ready for review!`
                            }));
                        }
                    });
                }

                if(response.request_status === 'In progress') {
                    this.data.collaborators.forEach(d => {
                        if((d.role != "Editor" || d.role != 'Creative Director') && d.id != this.userId) {
                            this.$socket.send(JSON.stringify({
                                recipient: d.id,
                                title: NOTIFICATION_TITLE.STATUS_UPDATE,
                                message: `${this.data.name} is now in progress`
                            }));
                        }
                    });
                }

                if(response.request_status === 'Storyboard - In progress') {
                    this.data.collaborators.forEach(d => {
                        if((d.role != "Editor" || d.role != 'Creative Director') && d.id != this.userId) {
                            this.$socket.send(JSON.stringify({
                                recipient: d.id,
                                title: NOTIFICATION_TITLE.STATUS_UPDATE,
                                message: `${this.data.name} is now storyboard - in progress`
                            }));
                        }
                    });
                }
            }
        },
        beforeDestroy() {
            Object.assign(this.$data, requestDateInitialState())
        },
        created() {
            this.$root.$refs.statusSection = this;
        },
        mounted(){
        },
        computed: {
            ...mapState('REQUEST', {
                data: state => state.request,
                loading: state => state.loading,
            }),
            ...mapGetters("AUTH", [
                'isUser',
                'isAdmin',
                'userRole',
                'isEditor',
                'isCreativeDirector',
                'userId'
            ]),
            requestId() {
                return this.$route.params.id
            },
            requestStatus() {
                if(this.data) {
                    if(this.isUser) {
                        if(this.data.request_status === "Ready for review") {
                            return "In progress"
                        }  
                    } 
                }

                 return this.data.request_status || ""
            },
            getStatusList() {
                return requestStatusList
                    .filter(({role}) => role.includes(this.userRole))
            }
        },

        watch: {
            data(latest) {
                this.fields = this.$lodash.pick(latest, ['date_receive', 'review_link'])
                this.refFields = this.$lodash.pick(latest, ['date_receive', 'review_link'])
            }
        }
    }
</script>