<template>
  <div class="card shadow comment-container my-4">
    <div class="card-header bg-transparent">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Comment feed </h3>
        </div>
        <div class="col text-right">
            <img :src="`/img/icons/common/sort-${sort}.svg`" class="image-icon sort" @click="sortComment"/>            
          
        </div>
      </div>
    </div>

    <loader :loading="loading" class="mt-4"/>
    <div class="card-body " v-if="!loading">
      <template v-if="!comments.length">
        <div class="comment-empty-container">
          <div class="comment-empty-image">
            <img src="/img/icons/common/empty-message.svg" class="image-icon" />
          </div>
          <span class="comment-empty-message">Nothing here yet</span>
        </div>
      </template>
      <template v-if="comments.length">
        <div class="comment-messages-container">
          <div class="comment-message" v-for="(comment, index) in commentShowed" :key="index" :class="{highlight: (commentHighlight == comment.id)}" :id="comment.id" >
            <div class="comment-user justify-content-between" :ref="comment.id">
              <div class="d-flex">
                <div class="avatar avatar-md rounded-circle" :class="getColors(0)">
                  <img :src="getPhoto(comment)" v-if="getPhoto(comment)" />
                  <span class="collab-member-initial" v-if="!getPhoto(comment)">
                                        {{ $tools.getInitial(getName(comment)) }}
                                    </span>
                </div>
                <div class="comment-user-details">
                  <div class="comment-name">{{ comment.user_name }}</div>
                  <div class="comment-date">{{ $moment(comment.created_date).format('lll') }}</div>
                </div>
              </div>
              
              <base-dropdown
                class="dropdown edit-comment"
                position="right"
                v-if="isCommentOwner(comment)"
              >
                <a
                    slot="title"
                    class="btn btn-edit-status btn-neutral btn-sm"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                  <img src="/img/icons/common/more.svg" class="image-icon" />
                </a>
                <template>
                  <div class="dropdown-item" @click="selectComment(comment)">Edit</div>
                  <div class="dropdown-item text-danger" @click="handleDeleteComment(comment)">Delete</div>
                </template>
              </base-dropdown>
            </div>
            <!-- <pre></pre> -->
            <!-- <span class="comment-content" style="white-space: pre-line">{{ comment.message }}</span> -->
            <!-- <div  class="comment-content" v-html="comment.message" v-linkified></div> -->
             <div ref="comment-content" class="comment-content" style="white-space: pre-line" v-html="getAutolink(comment.message, comment.notify_users)" :class="{ 'editor-highlight': comment.user.role === 'Editor' }" />
          </div>
        </div>
        <button @click="showedComment += moreComment" v-if="moreToShow" class=" btn btn-info">More...</button>
      </template>
    </div>
    <modal :show="previewImgComment" @close="previewImgComment = false" class="">
      <img :src="imgCommentUrl" style="width: 100%"/>
      <template slot="footer">
          <base-button type="link" @click="previewImgComment = false">Close</base-button>
      </template>
  </modal>
  </div>
</template>
<script>
import qs from 'qs';
import { mapState } from 'vuex';
import Autolinker from 'autolinker';
import { v4 } from 'uuid';

export default {
  name: 'request-comment-section',
  props: {
    isNew: {
      type: Boolean,
      description: 'to determine if new request or not',
    }
  },
  data () {
    return{
      mentionArray:[],
      sort: 'descending',
      showedComment: 5,
      moreComment: 2,
      commentHighlight:null,
      previewImgComment: false,
      imgCommentUrl: ''
    }
  },
  methods: {
    getAutolink(message, notify_users) {
      var linkedText = Autolinker.link(message, {
        mention: 'twitter',
        replaceFn: function(match) {
          switch (match.getType()) {
            case 'mention':  {
              let mention = match.getMention();       
              let n = 0;
              notify_users.forEach(element => {
                if(element.value === mention) {
                  n = element.id;
                }
              });
                
              return `<a href=/user/${n}>` + mention + '</a>';
            }
            default:
              break;
          }
        },
      })

      linkedText = linkedText.replace('img', `img style="cursor: pointer" title="Preview" class="img-comment-${v4()}"`)
      return linkedText
    },
    getName(comment) {
      return comment?.user?.name || `${comment?.user?.first_name} ${comment?.user?.last_name}`;
    },
    getPhoto(comment) {
      return comment?.user?.photo?.content_link || comment?.profile_image || '';
    },
    getColors(index) {
      const colors = ['bg-success', 'bg-purple', 'bg-warning', 'bg-info', 'bg-primary', 'bg-danger'];
      if (!colors[index])
        return colors[this.$tools.randomNumber(5)];
      return colors[index];
    },
    async getComments() {
      
      if(this?.requestId != 'new') {
        const response = await this.$store.dispatch('REQUEST/getRequestComments', qs.stringify({
          request_id: this?.requestId || this.request?.id,
          sort: [{ column: 'created_date', direction: this.sortDir ?? 'desc',limit:5 }],
         
        }));

        if(response) {
          let comments = [];
   
          if(this.user.role === 'User') {
            response.forEach(r => {
                  if(r.user.role !== 'Editor'){
                    comments.push(r)
                  }
              });
          } else {
            comments = response;
          }

          this.$store.commit('REQUEST/setComment', comments)
        }
      }else{
        let comments = [];
        this.$store.commit('REQUEST/setComment', comments)
      }
    },
    sortComment() {
      if(this.sort == 'ascending') {
        this.$store.commit('REQUEST/setCommentSortDirection', 'desc')
        this.sort = 'descending'
      } else {
        this.$store.commit('REQUEST/setCommentSortDirection', 'asc')
        this.sort = 'ascending'
      }
      this.getComments();
    },
    filterComment(comment) {
      if(this.user.role === 'User') {
          if(comment.user.role === 'Editor') {
              return false
          }
      }

      return true
    },
    async handleDeleteComment(comment) {
      if (!confirm('Are you sure you want to Delete this comment?'))
          return

      await this.$store.dispatch('REQUEST/deleteComment', comment.id);

      this.getComments();
    },
    selectComment(comment) {
      this.commentHighlight = comment.id
      this.$store.commit('REQUEST/setSelectedComment', comment)
    },
    isCommentOwner(comment) {
      return comment.user_id == this.user.id
    }
  },
  created() {
    this.$root.$refs.A = this;
    this.getComments();
  },
  updated() {
    document.querySelectorAll('[class*="img-comment"]').forEach(v => {
      v.addEventListener('click', () => {
        this.imgCommentUrl = v.getAttribute('src')
        this.previewImgComment = true
      })
    })
  },
  computed: {
    ...mapState('REQUEST', {
      request: state => state.request,
      comments: state => state.comments,
      sortDir: state => state.commentSortDirection,
      loading: state => state.loading
    }),
    ...mapState('AUTH', {
      user: state => state.user,
    }),
    collaborators() {
      return this.request?.collaborators || [];
    },
    requestId() {
      return this.$route.params.id;
    },
    commentShowed() {
      return this.comments.slice(0, this.showedComment)
    },
    moreToShow() {
      return this.showedComment < this.comments.length
    }
  },
  watch: {
    comments(g) {
     console.log('comments loaded', g)
    }
  }
};
</script>

<style scoped>
.edit-comment > a {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;

  box-shadow: none;
  background: transparent;

  border-radius: 100%;
}

.edit-comment > a:hover {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: #F4F5F7 !important;
}

.highlights__content {
  position: relative;
}
 
.highlights__placeholder {
  color: #ccc;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: -1;
}
 
.highlights__body-container {
  border-radius: 5px;
  border: 1px solid #eaeaea;
  padding: 16px;
}
 
.highlights__body {
  min-height: 60px;
}
 
.highlights {
  color: #ff3b8e;
}

 
.highlight {
  animation: blink 3s ease;
}

.sort {
  height: 25px;
  width: 25px;
}

@keyframes blink {
  0% {
    background-color: #8F64D9;
  }
  100% {
    background-color: white;
  }
}

.editor-highlight {
   background-image: linear-gradient(45deg, #8F64D9, white);
}
</style>
<style>
  .comment-content p {
    font-size: 12px !important;
    margin-bottom: unset !important
  }

  .comment-content img {
    width: 10% !important
  }
</style>