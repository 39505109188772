 
<template>
  <div class="card shadow messsage-container mt-4">
    <div class="card-body">
      <div class="message-input">
         <div class="form-group wrapper" :ref="'comment-section'">
          <at :members="items" name-key="value" :allowSpaces="false">
            <vue-editor 
              v-model="content"
              v-bind="$attrs" 
              :editorToolbar="toolbar"
              :editorOptions="editorSettings"
              ref="message"
              placeholder="Leave a comment here..."/>
          </at>
          <emoji-picker @emoji="selectEmoji" :search="searchEmoji">
            <button
              class="emoji-invoker"
              slot="emoji-invoker"
              slot-scope="{ events: { click: clickEvent } }"
              @click.stop="clickEvent"
            >
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current text-grey">
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"/>
              </svg>
            </button>
            <div slot="emoji-picker" slot-scope="{ emojis, insert }">
              <div class="emoji-picker" >
                <div class="emoji-picker__search mb-2">
                  <input type="text" v-model="searchEmoji" v-focus>
                </div>
                <div>
                  <div v-for="(emojiGroup, category) in emojis" :key="category">
                    <h5>{{ category }}</h5>
                    <div class="emojis">
                      <span
                        v-for="(emoji, emojiName) in emojiGroup"
                        :key="emojiName"
                        @click="insert(emoji)"
                        :title="emojiName"
                      >{{ emoji }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </emoji-picker>
        </div>
      </div>
      <div class="d-flex">
        <div class="col p-0">
          <span style="font-weight: bold; font-size: 12px">Add @ to tag someone in your comment</span>
        </div>
        <div class="d-flex">
          <base-button
                block
                type="link"
                class="btn-radius"
                @click="handeClear"
            >Cancel
            </base-button>
          <base-button
              block
              type="purple"
              class="btn-radius"
              @click="handleSave">
              {{ selectedComment ? 'Save' : 'Send' }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import At from 'vue-at'
import { VueEditor } from "vue2-editor";

import EmojiPicker from 'vue-emoji-picker'

const CUSTOM_TOOLBAR = [
  [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
  ['bold', 'italic', 'underline'],
  [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
  ['blockquote'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
  [{ 'indent': '-1'}, { 'indent': '+1' }],
  ['link']
]

export default {
  name: 'request-message-section-new',
  components: {  At, VueEditor, EmojiPicker },
  data: () => ({
    content: '',
    toolbar: CUSTOM_TOOLBAR,
    editorSettings: {
      bounds: `#quill-container`
    },
    client_id: '',
    items: [],
    notify_users: [],
    members: [],

    searchEmoji: '',
    editor: null
  }),
  methods: {
    selectEmoji(emoji) {
      if (!this.editor) {
        console.log('editor not found')
        return
      }

      const positionIndex = this.editor.getSelection(true).index
      this.editor.insertText(positionIndex, emoji, {}, true)
    },
    async handleSave() {

      this.content = this.content.replace('img', 'img class="image-comment"')

      const plainContent = this.content.replace(/<[^>]+>/g, '');
      if (!plainContent.trim() && !this.content.includes('img')) {
        return
      }
      
      const commentTextArr = plainContent.split(" ");

      this.notify_users = [];
      commentTextArr.forEach(comment => {
        if(comment.includes('@')) {
          this.items.forEach(item => {
            if(item.value == comment.split('@').pop()) {
              this.notify_users.push(item)
                }
              });
          }
      });

      var newMessage = {
        message: this.content,
        request_id: this.request.id,
        user_id: this.user.id,
        user_name: `${this.user.first_name} ${this.user.last_name}`,
        client_id: this.request.client_id,
        notify_users: this.notify_users,        
        user: this.user
      }

      const newArray = [newMessage].concat(this.comments)
      this.$store.commit('REQUEST/setComment', newArray)

      const comment = await this.$store.dispatch('REQUEST/createRequestComment', {
        message: this.content,
        request_id: this.request.id,
        user_id: this.user.id,
        client_id: this.request.client_id,
        notify_users: this.notify_users,
        members: this.request.members,
        comment_id: this.selectedComment?.id || null
      });

      if (!comment) {
        this.$notify({
          icon: 'fa fa-exclamation-triangle',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`,
        });

        return
      } 

      await this.$root.$refs.A.getComments()
      this.$root.$refs.A.commentHighlight = comment.comment.id
      const componentRef = Array.isArray(this.$root.$refs.A.$refs[comment.comment.id]) ? this.$root.$refs.A.$refs[comment.comment.id][0] : this.$root.$refs.A.$refs[comment.comment.id]                               
      componentRef.scrollIntoView({ behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      })

      this.handeClear()
    },
    handeClear() {
      this.content = ''
      this.$store.commit('REQUEST/setSelectedComment', null)
    },
    async getClientMembers() {
        const members = await this.$store.dispatch('CLIENT/getClientMembers', this.client_id)
        
        let arr = [];
        members.forEach(member => {
          if(this.user.role === 'User') {
            if(member.role !== 'Editor') {
              arr.push({
                id: member.id,
                value: `${member.first_name.trim()}${member.last_name.charAt(0).toUpperCase()}`
              })
            }
          } else if(this.user.role === 'Editor')  {
            if(member.role !== 'User') {
              arr.push({
                id: member.id,
                value: `${member.first_name.trim()}${member.last_name.charAt(0).toUpperCase()}`
              })
            }
          } else {
            arr.push({
              id: member.id,
              value: `${member.first_name.trim()}${member.last_name.charAt(0).toUpperCase()}`
            })
          }

        });

        this.items = arr
    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus()
      },
    },
  },
  mounted() {
    this.editor = this.$refs.message.quill
    console.log('editor', this.editor)
  },
  computed: {
    ...mapState('REQUEST', {
       request: state => state.request,
       comments: state => state.comments,
       sortDir: state => state.commentSortDirection,
       selectedComment: state => state.selectedComment
    }),
    ...mapState('AUTH', {
      user: state => state.user,
    }),
  },
  watch: {
    request(data) {
      this.client_id = data.client_id
      if (!this.client_id) {
        return
      }
      
      this.getClientMembers()
    },
    selectedComment(data) {
      if (data) {
        this.$refs['comment-section'].scrollIntoView({ behavior: 'smooth',
          block: 'center',
          inline: 'nearest'
        })
      }
      this.content = data ? data.message : ''
    }
  }
};
</script>
<style scoped>
  .wrapper {
    position: relative;
    display: inline-block;
  }

  .emoji-invoker {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
    padding: 0;
    background: transparent;
    border: 0;
  }

  .emoji-invoker:hover {
    transform: scale(1.1);
  }
  .emoji-invoker > svg {
    fill: #b1c6d0;
  }

  .emoji-invoker:focus {
    outline: unset !important
  }

  .emoji-picker {
    top: 32px;
    right: -225px;
    position: absolute;
    z-index: 1;
    font-family: Montserrat;
    border: 1px solid #ccc;
    width: 15rem;
    height: 20rem;
    overflow: scroll;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 1px 1px 8px #c7dbe6;
  }

  .emoji-picker__search {
    display: flex;
    height: 3vh !important
  }

  .emoji-picker__search > input {
    flex: 1;
    border-radius: 10rem;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    outline: none;
    font-size: 12px;
  }
  
  .emoji-picker h5 {
    margin-bottom: 0;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: 10px;
    cursor: default;
  }
  
  .emoji-picker .emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .emoji-picker .emojis:after {
    content: "";
    flex: auto;
  }

  .emoji-picker .emojis span {
    padding: 0.2rem;
    cursor: pointer;
    border-radius: 5px;
  }

  .emoji-picker .emojis span:hover {
    background: #ececec;
    cursor: pointer;
  }
</style>
<style>
  .ql-editor, .ql-editor p {
    font-size: 14px !important;
  }
  
  .ql-container {
    max-height: 60vh;
    overflow-y: scroll;
  }

  .ql-container::-webkit-scrollbar {
      display: none;
  }

  .ql-toolbar, .ql-container {
    border: 1px solid #f2f2f2 !important;
  }
</style>