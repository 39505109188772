<template>
   <div>
        <ul class="file-container no-height">
            <div v-if="this.currentS3Directory !== this.requestId">
                <base-button
                    class="mb-2"
                    type="neutral"
                    size="sm"
                    @click.prevent="goBack()"
                >
                    Back
                </base-button>
            </div>
            <div v-if="!pending && requestId"> 
                <li v-for="(file) in fileShowed" :key="file.id">
                    <div class="file-item">
                        <div class="file-item-head d-flex justify-content-between flex-wrap">
                            <div class="file-details pb-2" style="cursor: pointer" @click.prevent="file.isFolder ? handleOpenFolder(file.Key) : null">
                                <img 
                                    contain
                                    width="32px"
                                    class="file-type image-icon" 
                                    :src="`/img/icons/common/${getS3Icon(file)}`"
                                />
                                <div class="file-name" v-if="getS3FileName(file).length<8" :title="getS3FileName(file)">{{ getS3FileName(file) }}</div>
                                <div class="file-name" v-else :title="getS3FileName(file)">{{ getS3FileName(file).substring(0,8)+"..." }}</div>
                            </div>

                            <div class="file-uploaded-action-container pb-2">
                                    <!-- v-if="file.Size > 0" -->
                                <base-button
                                    type="neutral"
                                    size="sm"
                                    class="file-uploaded-action"
                                    @click.prevent="handleS3DownloadFile(file.Key, file.Size)"
                                >
                                    <img class="image-icon " src="/img/icons/common/download.svg"/>
                                </base-button>

                                    <base-button
                                    type="danger"
                                    size="sm"
                                    class="file-uploaded-action"
                                    @click.prevent="handleConfirmationDialog(file)"
                                >
                                    <img class="image-icon " src="/img/icons/common/trash.svg"/>
                                </base-button>
                            </div>
                        </div>

                        <div class="file-item-head d-flex justify-content-between flex-wrap">
                            <div>
                                <div v-if="file.Size > 0" class="file-upload-status">{{ getS3FileSize(file) }}</div>
                                <div v-if="file.isFolder" class="file-upload-status">Folder</div>
                            </div>

                            <div v-if="file.uploader" class="file-upload-status">{{ getFormattedDate(file.uploader.created_date) }} | <span :title="file.uploader.uploaded_by">{{ file.uploader.uploaded_initial }}</span>
                            </div>
                                 <!-- <span
                                    class="avatar avatar-xs rounded-circle"
                                    :class="getColors()"
                                >
                                    <img :src="file.uploader.profile_image" v-if="file.uploader.profile_image" />
                                    <span class="collab-member-initial" v-if="!file.uploader.profile_image">
                                    {{ $tools.getInitial(getName(row) || row.email) }}
                                    </span>
                                </span> -->
                        </div>
                    </div>

                    <!-- <base-dropdown
                    class="dropdown"
                    position="right"
                    >
                    <a
                        slot="title"
                        class="btn file-uploaded-action btn-neutral btn-sm"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i class="fas fa-ellipsis-v"/>
                    </a>
                    <template>
                        <a class="dropdown-item" href="#">Action</a>
                        <a class="dropdown-item" href="#">Another action</a>
                        <a class="dropdown-item" href="#">Something else here</a>
                    </template>
                    </base-dropdown> -->
                </li> 
               
            </div>
            
            <div v-if="pending">
                <li v-for="(file) in filtered_documents" :key="file.id">
                    <div class="file-item" >
                        <div class="file-item-head">
                            <div class="file-details">
                                <img class="file-type image-icon" :src="`/img/icons/common/${getIcon(file.type, file)}`" />
                                <div class="file-name">{{ getFileName(file) }}</div>
                            </div>

                            <div v-if="!file.time">
                                <base-button type="neutral" class="file-upload-action" rounded>
                                    <img
                                        class="image-icon"
                                        src="/img/icons/common/cancel.svg"
                                        v-if="file.percent <= 99 && file.status === 'in-progress' && !isFileCancelled(file)"
                                        @click.prevent="handleCancelUpload(file.id)"
                                    />
                                    <img
                                        class="image-icon"
                                        src="/img/icons/common/trash.svg"
                                        v-if="(file.percent === 0 && file.status === 'pending') || isFileCancelled(file)"
                                        @click.prevent="handleRemoveDocument(file.id)"
                                    />
                                </base-button>
                            </div>
                        </div>

                        <base-progress
                        type="success"
                        :show-percentage="false"
                        :class="`pt-0 file-upload-progress ${getProgressType(file.type, file)}`"
                        :value="file.percent"
                        v-if="!file.time"
                        />

                        <div class="file-upload-status" v-if="!file.time && isFileCancelled(file)">Cancelled{{ getDirectory2(file) }}</div>
                        <div class="file-upload-status" v-if="!file.time && !isFileCancelled(file) && file.percent === 0 && file.status === 'pending'">Pending{{ getDirectory2(file) }}</div>
                        <div class="file-upload-status" v-if="!file.time && !isFileCancelled(file) && file.percent <= 99 && file.status === 'in-progress'">In progress - {{ file.percent }}% done{{ getDirectory2(file) }}</div>
                        <div class="file-upload-status" v-if="!file.time && !isFileCancelled(file) && file.percent >= 100">Completed{{ getDirectory2(file) }}</div> 
                    </div>
                </li>
            </div>
            
        </ul>
        <!-- <a href="#!" @click="showedFile += moreFile" v-if="moreToShow" class="cursor-pointer" > See all </a> -->
        <a href="#!"  v-if="moreToShow" class="cursor-pointer" @click="openLink"> See all </a>
    <modal :show="showConfirmationDialog" @close="handleConfirmationDialog" type="mini">
        <h3 slot="header" class="modal-title" id="modal-title-default">Delete {{ getS3FileName(this.selectedItem) }}</h3>
        <div>
            Warning! Are you sure to delete this?
        </div>
        <template slot="footer">
            <base-button type="link" class="ml-auto btn-radius" @click="handleConfirmationDialog">
            Close
            </base-button>
            <base-button type="danger" class="btn-radius" @click.prevent="handleDelete">
            Delete
            </base-button>
        </template>
    </modal>  
    <modal :show="isLinkOpen" @close="openLink" modalClasses="modal-xl asset-modal">
      <h1 slot="header" class="modal-title" > Uploaded Assets</h1>
       <div>
           <div class="container">
            <div v-if="this.currentS3Directory !== this.requestId">
                <base-button
                    class="mb-2"
                    type="neutral"
                    size="sm"
                    @click.prevent="goBack()"
                >
                    Back
                </base-button>
            </div>
            <div v-if="!pending && requestId" class = "col-lg-12 row" style="padding:0px !important"> 
                <div v-for="(file) in filtered_uploaded_docs" :key="file.id" class="col-lg-1 my-3 mr-4 ml-4 all-files" style="padding:0px !important">
                    
                        
                        <picture style="cursor: pointer" @click.prevent="file.isFolder ? handleOpenFolder(file.Key) : null">
                        
                        <img 
                                contain
                                width="60px"
                                class="file-type image-icon" 
                                :src="`/img/icons/common/${getS3Icon(file)}`"
                                :title="getS3FileName(file)"
                            />
                        </picture>
                        <div class="file-name" v-if="getS3FileName(file).length<15" :title="getS3FileName(file)">{{ getS3FileName(file) }}</div>
                        <div class="file-name" v-else :title="getS3FileName(file)">{{ getS3FileName(file).substring(0,15)+"..." }}</div>
                        <div>
                            <div v-if="file.Size > 0" class="file-name">{{ getS3FileSize(file) }}</div>
                            <div v-if="file.isFolder" class="file-name">Folder</div>
                        </div>
                        <div>
                         <div v-if="file.uploader" class="file-name">{{ getFormattedDate(file.uploader.created_date) }} | <span :title="file.uploader.uploaded_by">{{ file.uploader.uploaded_initial }}</span></div>
                         <div v-if="!file.uploader" class="file-name">-----------------------</div>
                         <div v-if="!file.uploader" class="file-name">------------------------</div>
                        </div>
                        <div class="file-uploaded-action-container">
                                    <!-- v-if="file.Size > 0" -->
                                <a href="#"  @click.prevent="handleS3DownloadFile(file.Key, file.Size)" title="Download" style="margin-right: 20%;" >
                                    <img class="image-icon " src="/img/icons/common/download.svg"/>
                                </a>
                                 <a href="#"   @click.prevent="handleRemoveDocument(file.id)" title="Delete">
                                   
                                    <img class="image-icon " src="/img/icons/common/trash.svg"/>
                                 </a>
                            </div>
                    
                </div> 
               
            </div>
            
            <!-- <div v-if="pending">
                <li v-for="(file) in filtered_documents" :key="file.id">
                    <div class="file-item" >
                        <div class="file-item-head">
                            <div class="file-details">
                                <img class="file-type image-icon" :src="`/img/icons/common/${getIcon(file.type, file)}`" />
                                <div class="file-name">{{ getFileName(file) }}</div>
                            </div>

                            <div v-if="!file.time">
                                <base-button type="neutral" class="file-upload-action" rounded>
                                    <img
                                        class="image-icon"
                                        src="/img/icons/common/cancel.svg"
                                        v-if="file.percent <= 99 && file.status === 'in-progress' && !isFileCancelled(file)"
                                        @click.prevent="handleCancelUpload(file.id)"
                                    />
                                    <img
                                        class="image-icon"
                                        src="/img/icons/common/trash.svg"
                                        v-if="(file.percent === 0 && file.status === 'pending') || isFileCancelled(file)"
                                        @click.prevent="handleRemoveDocument(file.id)"
                                    />
                                </base-button>
                            </div>
                        </div>

                        <base-progress
                        type="success"
                        :show-percentage="false"
                        :class="`pt-0 file-upload-progress ${getProgressType(file.type, file)}`"
                        :value="file.percent"
                        v-if="!file.time"
                        />

                        <div class="file-upload-status" v-if="!file.time && isFileCancelled(file)">Cancelled{{ getDirectory2(file) }}</div>
                        <div class="file-upload-status" v-if="!file.time && !isFileCancelled(file) && file.percent === 0 && file.status === 'pending'">Pending{{ getDirectory2(file) }}</div>
                        <div class="file-upload-status" v-if="!file.time && !isFileCancelled(file) && file.percent <= 99 && file.status === 'in-progress'">In progress - {{ file.percent }}% done{{ getDirectory2(file) }}</div>
                        <div class="file-upload-status" v-if="!file.time && !isFileCancelled(file) && file.percent >= 100">Completed{{ getDirectory2(file) }}</div> 
                    </div>
                </li>
            </div> -->
            
        </div>
       </div>       
               
    </modal>
   </div>
   
   
    
</template>
<script>
    // import flattenDeep from 'lodash/flattenDeep'
    import findIndex from 'lodash/findIndex'
    import cloneDeep from 'lodash/cloneDeep'
    // import map from 'lodash/map'
    // import UploadDocument from '@/utils/upload'
    // import tools from '@/utils/tools'
    // import { v4 } from 'uuid'
    import { mapState } from "vuex"
    // import axios from 'axios'
    import DownloadDocument from '@/utils/download';

    const defaultState = () => ({
        isOpen: null,
        isUploadStarted: false,
        currentS3Directory: '',
        showConfirmationDialog: false,
        selectedItem: null,
        showedFile: 4,
        moreFile: 500,
        isLinkOpen: false,
    })

    export default {
        name: 'file-list',
        components: {

        },
        props:{
            resource: {
              type: String,
              default: '',
              description: 'api node element | resource'
            },
            client_id: {
              type: String,
              default: '',
              description: 'need to filter the pending documents in global state'
            },
            batchId: {
              type: String,
              default: '',
              description: 'need to filter the pending documents in global state'
            },
            filterNotCompleted: {
                type: Boolean,
                default: false,
                description: 'only display in-progress or pending documents'
            },
            showUploaded: {
                type: Boolean,
                default: false,
                description: 'only display in-progress or pending documents'
            },
            requestId: {
              type: String,
              default: '',
              description: 'need to filter the pending documents in global state'  
            },
            pending: {
              type: Boolean,
              default: false,
              description: 'need to filter the pending documents in global state'
            },
        },
        data: defaultState,
       /*
        () => ({
            files: [
                {
                    item: { name: 'Untitled.xlsx'},
                    time: 'Added 1 day ago',
                    percent: 100,
                    type: 'xlsx'
                },{
                    item: { name: 'Image.png'},
                    percent: 70,
                    type: 'xlsx'
                },
                {
                    item: { name: 'Untitled.gdoc'},
                    percent: 0,
                    type: 'word'
                }
            ],
        }),
        */
        methods: {
            getColors(index) {
                const colors = ['bg-success', 'bg-purple', 'bg-warning', 'bg-info', 'bg-primary', 'bg-danger'];
                if (!colors[index])
                return colors[this.$tools.randomNumber(5)];
                return colors[index];
            },
             getFormattedDate(key) {
                return this.$moment(key).format('llll')
            },
            getDirectory(val) {
                let dir = val.split('/')
                return val.replace(dir[0], '')
            },
            getDirectory2(file){
               if(file.directories.length > 1){
                    const result = file.item.fullPath.replace(file.item.name, '')

                    return `- ${result}`
                }
                return ''
            },
            handleRemoveDocuments(fileId) {
                this.$store.commit('DOCUMENT/removeDocument', {
                    fileId,
                    client_id: this?.client_id,
                    resource: this?.resource,
                })
            },
            handlleCloseDialog() {
                this.filtered_documents.forEach(file_doc => (
                    this.handleCancelUpload(file_doc?.id)
                ))

                this.handleRemoveDocuments()
                this.$emit('toggle', false)
            },
            handleRemoveDocument(fileId) {
                this.handleCancelUpload(fileId)
                this.handleRemoveDocuments(fileId)
            },
            handleCancelUpload(fileId) {
                const documents = cloneDeep(this?.filtered_documents || [])
                const index = findIndex(documents, { id: fileId })
                const file_doc = documents[index]

                if (file_doc) {
                    file_doc?.upload?.cancelUpload()
                    documents.splice(index, 1, { ...file_doc, status: 'cancelled' })
                    this.$store.commit('DOCUMENT/setDocuments', this.$lodash.keyBy(documents, 'id'))
                }
            },
            isFileCancelled(file_doc) {
              
                const cancel = !file_doc?.upload
                    ? false
                    : file_doc?.upload?.isUploadCancelled()

                return cancel
            },
            handleOpenFolder(data){
                this.currentS3Directory = `${this.currentS3Directory}/${data}`
                this.$store.commit('DOCUMENT/setDirectory', this.currentS3Directory);
            },
            getProgressType(type, file) {
                const name = this.getFileName(file)
                if ((type || name).includes('word'))
                    return 'primary'

                if (name.includes('xlsx'))
                    return 'success'

                if ((type || name).includes('image') || (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(name))
                    return 'pink'

                return 'green'
            },
            getIcon(type, file) {
                const name = this.getFileName(file)
                if ((type || name).includes('word'))
                    return 'gdoc.svg'

                if (name.includes('xlsx'))
                    return 'gsheet.svg'

                if ((type || name).includes('image') || (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(name))
                    return 'image.svg'

                return 'glink.svg'
            },
            getFileName(file_doc) {
                return file_doc?.file?.name || file_doc?.item?.name || ''
            },
            goBack(){
                let str = this.currentS3Directory.split('/').slice(-1)
                this.currentS3Directory = this.currentS3Directory.replace(`/${str[0]}`, '')
                this.$store.commit('DOCUMENT/setDirectory', this.currentS3Directory);
            },
            getS3Icon(file){
                if(!file) return
                
                const name = this.getS3FileName(file)
                if(file.isFolder){
                    return 'folder.svg'
                }

                const extension = (name.split('.').pop())?.toLowerCase()
                if(extension === 'docx' || extension === 'docs'){
                    return 'gdoc.svg'
                }

                if(extension === 'xlsx' || extension === 'xls'){
                    return 'xls.svg'
                }
                if(extension === 'jpg' || extension === 'jpeg'){
                    return 'jpg.svg'
                }

                if((/\.(ppg|prproj)$/i).test(name)){
                    return 'proj.png'
                }

                if((/\.(gif||tiff?|png|webp|bmp)$/i).test(name)){
                    return 'png.svg'
                }
                
                return extension+'.svg'
            },
            getS3FileName(file_doc){
                if(!file_doc) return
                const key = file_doc.Key 
                return key 
            },
            getS3FileSize(file){
                const bytes = file.Size;
                
                if(bytes === 0) return '0 Bytes'
                
                const k = 1024  
                const dm = 2 // decimal place
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

                const i = Math.floor(Math.log(bytes) / Math.log(k))
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
            },
            handleConfirmationDialog(item){
                this.showConfirmationDialog = !this.showConfirmationDialog
                this.selectedItem = item
            },
            async handleDelete(){
               try {
                let file = this.selectedItem

                if(!file) return
                    this.showConfirmationDialog = !this.showConfirmationDialog
                    this.selectedItem = null
                
                if(file.Size > 0) {
                    return await this.$store.dispatch('DOCUMENT/deleteS3Object', `${this.currentS3Directory}/${file.Key}`);
                } else {
                    return await this.$store.dispatch('DOCUMENT/deleteS3MultipleObject', `${this.currentS3Directory}/${file.Key}`);
                }
                } catch (error) {
                return error
            }
            },
            async getS3AssetList() {
                try {
                  if(this?.requestId){
                      return await this.$store.dispatch('DOCUMENT/getS3AssetList', this.requestId);
                  }
                } catch (error) {
                    return error
                }
            },
            async handleS3DownloadFile(key, fileSize){
                try {
                    key = `${this.currentS3Directory}/${key}`

                    new DownloadDocument(key, {
                        directDownload: fileSize > 0,
                        file: (f) => {
                            window.open(f)
                        }
                    });

                  } catch (error) {
                    return error
                }
            },
            openLink() {
            this.isLinkOpen = !this.isLinkOpen;
          },
        },
        created() {
            this.currentS3Directory = this.requestId
            this.getS3AssetList()
        },
        computed: {
            ...mapState('DOCUMENT', {
                request_documents: state => state.documents,
                uploaded_documents: state => state.data,
            }),
            filtered_documents() {
                let filtered = this.$lodash.values(this.request_documents)?.filter(({ client_id, resource }) => {
                    return client_id === this.client_id && resource === this.resource
                })

                if (this.batchId)
                    filtered =  filtered?.filter(({ batchId }) => batchId === this.batchId)

                if (this.filterNotCompleted)
                    filtered =  filtered?.filter(({ status }) => status !== 'completed')
                return filtered
            },
            fileShowed() {
                
                return this.filtered_uploaded_docs.slice(0, this.showedFile)
            },
            moreToShow() {
                return this.showedFile < this.filtered_uploaded_docs.length
            },
            filtered_uploaded_docs() {
                let filtered = this.uploaded_documents?.filter(({ Directory }) => Directory === this.currentS3Directory)
               return filtered
            },
        },

        beforeDestroy() {
            console.log('before')
        }
    };
</script>

<style lang="scss">
    .no-height {
        height: unset !important;
        min-height: unset !important;
    }

    .file-upload-progress {

        .progress {
            box-shadow: none !important;
            height: 4px !important;
            border-radius: 24px;
            background: #E8E8E8 !important;
            margin-bottom: unset !important;
        }

        &.success .progress-bar {
            background: #43A047 !important;
        }

        &.primary .progress-bar {
            background: #559EF4 !important;
        }

        &.green .progress-bar {
            background: #17B897  !important;
        }

        &.pink .progress-bar {
            background: #FB6BA0 !important;
        }
    }

    .file-container {
        max-height: 465px;
        overflow-y: auto;
        padding: 0;
        margin: 0;
        margin-right: -24px;
        padding-right: 24px;

        .file-item-head {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 8px 0 0;
            margin-bottom: 8px;

            .file-details {
                display: flex;
                align-items: center;

                .d-flex {
                    flex-direction: column;
                }
            }
        }


        .file-upload-status {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #8C8C8C;
            margin-top: calc(1rem - 8px)
        }

        .file-item {
            min-height: 95px;
            width: 100%;
            list-style-type: none;
            position: relative;
            margin-bottom: 16px;
            &:before {
                display: block;
                content: '';
                width: 100%;
                border-bottom: 1px solid #E8E8E8;
                margin: 8px 0;
                position: absolute;
                bottom: -15px;
            };
        }

        .file-type {
            margin-right: 16px;
        }

        .file-uploaded-action-container {
            display: flex;
        }

        .file-uploaded-action.btn {
            width: 32px;
            height: 32px;
            border: 1px solid #D1D1D1;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
            box-shadow: none;
        }

        .file-upload-action {
            width: 24px;
            height: 24px;
            padding: 0;
            margin: 0;
            text-align: center;
            background: transparent;
            box-shadow: none;
            border: none;
        }

        .file-time {
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: #8C8C8C;
            text-transform: capitalize;
        }

        .file-name {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #191919;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 140px;

            &:not(.uploaded) {
                max-width: 200px;
            }
        }

        .file-upload-action {

        }
    }
</style>