<template>
    <div class="card shadow request-container mt-4">  
        <div class="card-header bg-transparent" role='button' @click="toggle">
            <div class="row align-items-center">
                <div class="col ">
                    <h3 class="mb-0">Deliverables</h3>
                </div>
                <div class="col text-right">
                    <span class="fa" :class="{ 'fa-eye-slash': showSection, 'fa-eye': !showSection }" role='button'></span>
                </div>
            </div>
        </div>      
        <loader :loading="loading" class="mt-4"/>
        <div class="p-4" v-if="!loading" v-show="showSection">
            <div class="deliverables-container">
                <base-button type="success" class="btn-radius" :size="'md'" @click="handleAddDeliverable">Add</base-button>
                <div class="text-right float-right mb-4 d-flex justify-content-between">
                    <multiselect    
                        class="mr-4"
                        v-model="fields.type"
                        :options="deliverableTypeList"
                        :close-on-select="true"
                        :clear-on-select="false"
                        placeholder="Filter Type"
                        selectLabel=""
                        deselectLabel=""
                        selectedLabel=""
                        @input="handleTableChange"
                    />

                    <multiselect
                        v-model="fields.status"
                        :options="deliverableStatusListFilter"
                        :close-on-select="true"
                        :clear-on-select="false"
                        placeholder="Filter Status"
                        label="name"
                        track-by="id"
                        selectLabel=""
                        deselectLabel=""
                        selectedLabel=""
                        @input="handleTableChange"
                    />
                </div>
                <div class="table-responsive mt-4 table-container" >
                    <base-table
                        class="table align-items-center table-flush table-hover"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                        :columns="columns"
                        :sortDirection="sort.direction"
                        :sortColumn="sort.column"
                        v-model="sort"
                    >
                        <template slot-scope="{row}">
                            <tr @click="selectDeliverable(row)" :ref="`${row.id}-deliverable`" :class="{highlight: (deliverableHighlight == `${row.id}-deliverable`)}">
                                <td class="">{{$moment(row.created_date).format('L')}}</td>
                                <td class="text-center">
                                    <img v-bind:src="platformLogo(row.platform, row.placement).src" :title="platformLogo(row.platform, row.placement).title" 
                                        :style="{ 'width': platformLogo(row.platform, row.placement).width || '45%' }"/>
                                </td>
                                <td class="wrap-cell text-center">{{ row.placement || '---'}}</td>
                                <td class="text-center">{{ row.type ? `${row.type} ${row.index}` : '---'}}</td>
                                <td class="text-center">{{ row.length ? row.length+'s' : '---'}}</td>
                                <td class="text-center">{{ row.dimension || '---'}}</td>
                                <td class="text-center">
                                    <div v-if="row.reviewLink"
                                        class="popup" 
                                        @click.stop="$event.preventDefault(); $event.stopPropagation()">
                                        <img @click="showLinkOptions(row.id)" v-bind:src="'/img/brand/vidsprint_logo.png'" style="width: 30%"/> 
                                        <div class="options d-flex justify-content-between" :ref="`${row.id}-popup`">
                                            <small v-clipboard:copy="row.reviewLink" @click="hideLinkOptions">Copy Link</small>
                                            <small class="px-2">|</small>
                                            <small @click="viewLink(row.reviewLink); hideLinkOptions()">View Link</small>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <img v-bind:src="'/img/brand/vidsprint_logo.png'" style="width: 30%; opacity: 0.2;"/> 
                                    </div>
                                </td>
                                <td @click.stop="$event.preventDefault(); $event.stopPropagation()">
                                    <multiselect
                                        class="update-status-container"
                                        v-model="row.status"
                                        :options="deliverableStatusList(row.status)"
                                        :close-on-select="true"
                                        :clear-on-select="false"
                                        placeholder="Update Status"
                                        selectLabel=""
                                        deselectLabel=""
                                        selectedLabel=""
                                        @input="updateStatus(row)"
                                        :open-direction="'bottom'"
                                        @open="hideLinkOptions"
                                        >
                                        <template slot="singleLabel">
                                           <span :title="row.status">{{ row.status }}</span>
                                        </template>
                                        <template slot="option" slot-scope="props">
                                            <span :title="props.option.name">{{ props.option.name }}</span>
                                        </template>
                                    </multiselect>                          
                                </td>
                            </tr>
                            <tr class="d-none" :ref="row.id" :style="{'border': '1px solid #eaecef', 'background-color': '#f7f9fc !important'}">
                                <td colspan="8">
                                    <div class="show-deliverable-section">
                                        <div class="mt-4 p-2 row">
                                            <div class="col">
                                                <small>Platform: </small>
                                                <label class="form-control-label long-text">
                                                    {{ row.platform }}
                                                </label>
                                                <br>
                                                <small>Placement: </small>
                                                <label class="form-control-label long-text">
                                                    {{ row.placement }}
                                                </label>
                                                <br>
                                                <small>Type: </small>
                                                <label class="form-control-label long-text">
                                                    {{ row.type }} {{ row.index }}
                                                </label>
                                                <br>
                                                <small>Length: </small>
                                                <label class="form-control-label long-text">
                                                    {{ row.length ? row.length+'s' : '---' }}
                                                </label>
                                                <br>
                                                <small>Dimension: </small>
                                                <label class="form-control-label long-text">
                                                    {{ row.dimension }}
                                                </label>
                                            </div>
                                            <div class="col">
                                                <small>Key Messaging: </small>
                                                <label class="long-text" v-html="formatKeyMessaging(row.keyMessaging)"></label>
                                                <br>
                                                <small>CTA: </small>
                                                <label class="form-control-label long-text">
                                                    {{ row.CTA || '---' }}
                                                </label>
                                                <br>
                                                <small>Concept: </small>
                                                <label class="form-control-label long-text">
                                                    {{ row.notes || '---' }}
                                                </label>
                                                <br>
                                                <small>Status: </small>
                                                <label class="form-control-label long-text">
                                                    {{ row.status }}
                                                </label>
                                            </div>
                                        </div>
                                        <div v-if="isAdmin" class="mt-4 px-2">
                                            <button class="float-right btn btn-sm btn-danger" @click="deleteDeliverable(row.id)" >Delete Deliverable</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </base-table>
                </div>
                <div class="card-footer d-flex justify-content-between bg-transparent">
                    <div class="">
                        <select v-model="size" class="form-control px-1 text-center">
                            <option v-for="(page, index) in table.sizeOptions" :key="index">{{ page }}</option>
                        </select>
                    </div>
                    <base-pagination :total="Number(total)" :value="Number(pagination)" :perPage="Number(table.size)" v-model="pagination" />
                </div>
            </div>
        </div>
        <modal v-if="showModalReviewLink" :show="showModalReviewLink" :clickToClose="false">
            <h3 slot="header" class="modal-title" id="modal-title-request-receive-date">{{ reviewLinkCTA }}</h3>
            <base-input
                type="text"
                required
                v-model="fields.reviewLink.value"
                :error="fields.reviewLink.error"
                :valid="!fields.reviewLink.error ? true : false"
                @input="validate(fields.reviewLink)"
                @blur="validate(fields.reviewLink)"
            />
            <loader :loading="loading" />
            <template slot="footer" v-if="!loading">
                <base-button type="link" @click="handleReviewLinkClose">Close</base-button>
                <base-button type="primary" @click.prevent="handleReviewLinkUpdate">Save changes</base-button>
            </template>
        </modal>
        <modal v-if="showProjectDialog" :show="showProjectDialog" :clickToClose="false" class="project-dialog">
            <request-section-new 
                :addDeliverableOnly="true" 
                @closeDialog="handleToggleProjectDialog" 
                :componentKey="projectDialogKey" 
                :key="projectDialogKey"
                :projectID="request.id" >
            </request-section-new>
        </modal>
    </div>
</template>
<script>
    import qs from "qs";
    import omit from "lodash/omit";

    import debounce from "lodash/debounce";
    import { deliverableColumns } from "../model";
    import { mapState, mapGetters } from "vuex";
    import Multiselect from 'vue-multiselect';
    import VueClipboard from 'vue-clipboard2';
    import Vue from 'vue';
    import { v4 } from 'uuid';
    import RequestSectionNew from './RequestSectionV3.vue';

    Vue.use(VueClipboard)

    export default {
        name: "request-deliverable-container",
        components: {
            Multiselect,
            RequestSectionNew
        },
        props: {},
        data: () => ({
            loading: false,
            showSection: true,
            selectedDeliverable: null,
            showModalReviewLink: false,
            showProjectDialog: false,
            projectDialogKey: v4(),
            deliverableHighlight: null, 
            fields: {
                reviewLink: {
                    value: '',
                    error: '',
                    required: true
                },
                status: '',
                type: ''
            },
            columns: deliverableColumns,
            table: {
                sort: [{
                    column: 'type',
                    direction: 'asc'
                }],
                status: [],
                type: '',
                page: 0,
                size: 10,
                sizeOptions: [5, 10, 50, 100, 250, 500],
            },
        }),
        created() {
            this.$root.$refs.deliverableSection = this;
            this.handleTableChange()
        },
        mounted() {
            document.addEventListener('click', () => {
                // close all open popup options
                Object.keys(this.$refs).forEach(v => {
                    if (this.$refs[v]?.className.match('show')) {
                        this.$refs[v].classList.toggle('show')
                    }
                })
            });
            console.log('deliverable mounted', this.projectDialogKey)
        },
        methods: {
            toggle() {
                this.showSection = !this.showSection
            },
            validate(field) {
                field.error = ''
                if (field.required && !field.value) {
                    field.error = 'This field is required'
                }
            },
            async handleTableChange() {
                this.table.status = [];

                if(this.fields.status.name !== '[All]') {
                    this.table.status.push(this.fields.status.name) 
                }

                this.table.type = ''
                if (this.fields.type != '[All]') {
                    this.table.type  = this.fields.type
                }

                const params = omit(this.table, ["sizeOptions"])
                params['requestID'] = this.requestID

                this.loading = true
                await this.$store.dispatch("REQUEST/listDeliverables", qs.stringify(params))
                this.loading = false
            },
            selectDeliverable(data) {
                this.selectedDeliverable = data
                this.$refs[data.id].classList.toggle('d-none')
            },
            handleReviewLinkClose() {
                this.fields.reviewLink.value = ''
                this.showModalReviewLink = false
                this.handleTableChange()
            },
            async handleReviewLinkUpdate() {
                this.validate(this.fields.reviewLink)
                if (this.fields.reviewLink.error) {
                    return
                }

                this.selectedDeliverable = { ...this.selectedDeliverable, updatedReviewLink: this.fields.reviewLink.value}
                await this.updateStatus(this.selectedDeliverable)
                this.fields.reviewLink.value = ''
                this.showModalReviewLink = false
            },
            async updateStatus(data) {
                this.selectedDeliverable = data

                if ((data.status.name.toLowerCase() == 'latest version ready' || data.status.name.toLowerCase() == 'storyboard - ready for internal review')
                    && !data.updatedReviewLink) {
                    this.fields.reviewLink.value = data.reviewLink
                    this.showModalReviewLink = true
                    return
                }

                const { id, status, reviewLink, updatedReviewLink } = data
                const payload = {
                    id,
                    status: status.name,
                    reviewLink: updatedReviewLink || reviewLink
                }

                this.loading = true
                await this.$store.dispatch("REQUEST/updateDeliverableStatus", payload)
                this.$notify({
                    icon: "ni ni-check-bold",
                    type: "success",
                    title: "Success",
                    message: `Status successfully updated`,
                })
                this.loading = false

                await this.handleTableChange()

                // update project status to ASSIGNED if deliverable status is Assigned
                if ((status.name.toUpperCase() == 'ASSIGNED') && this.request.request_status.toUpperCase() != 'ASSIGNED' ) {
                    await this.$store.dispatch("REQUEST/updateRequestStatus", {
                        id: this.request.id,
                        status: 'Assigned'
                    })

                    this.$root.$refs.statusSection.handleGetDetails()
                }

                // update project status to IN - PROGRESS if deliverable status is In Production or Storyboard - In Progress
                if ((status.name.toUpperCase() == 'IN PRODUCTION' || status.name.toUpperCase() == 'STORYBOARD - IN PROGRESS') 
                    && this.request.request_status.toUpperCase() != 'IN PROGRESS' ) {
                    await this.$store.dispatch("REQUEST/updateRequestStatus", {
                        id: this.request.id,
                        status: 'In progress'
                    })

                    this.$root.$refs.statusSection.handleGetDetails()
                }

                // update project status to APPROVED if all deliverables are approved
                const unApprovedDeliverables = this.records.filter(v =>(v.status?.toUpperCase() || v.status.name.toUpperCase()) != 'APPROVED')
                if (!unApprovedDeliverables.length) {
                    await this.$store.dispatch("REQUEST/updateRequestStatus", {
                        id: this.request.id,
                        status: 'Approved'
                    })

                    this.$root.$refs.statusSection.handleGetDetails()
                }

                this.$root.$refs.RequestLogs.handleTableChange()
                if (data.status.name.toLowerCase() == 'latest version ready') {
                    this.$root.$refs.A.getComments()
                }
            },
            platformLogo(platform, placement) {
                const platformLogoMap = {
                    'facebook / instagram / threads': { title: 'Facebook / Instagram / Threads', src: '/img/platforms/fb-ig-threads.png', width: '100%' },
                    'facebook': { title: 'Facebook', src: '/img/platforms/fb.png' },
                    'instagram': { title: 'Instagram', src: '/img/platforms/ig.png' },
                    'threads': { title: 'Threads', src: '/img/platforms/threads.png'},
                    'youtube': { title: 'YouTube', src: '/img/platforms/youtube.png' },
                    'pinterest': { title: 'Pinterest', src: '/img/platforms/pinterest.png' },
                    'tiktok': { title: 'TikTok', src: '/img/platforms/tiktok.png' },
                    'twitter': { title: 'Twitter', src: '/img/platforms/twitter.png' },
                    'linkedin': { title: 'LinkedIn', src: '/img/platforms/linkedin.png' },
                    'snapchat': { title: 'Snapchat', src: '/img/platforms/snapchat.png' },
                    'google': { title: 'Google', src: '/img/platforms/google.png' },
                    'amazon': { title: 'Amazon', src: '/img/platforms/amazon.png' },
                    'ctv': { title: 'CTV', src: '/img/platforms/ctv.png'},
                    'others': { title: 'Custom Dimensions', src: '/img/platforms/others.png'}
                }

                if (placement && (platform.toLowerCase().includes('facebook / instagram / threads')
                    || platform.toLowerCase().includes('facebook / instagram'))) {
                        const placementPlatform = placement.split(' ')
                        if (placementPlatform.length) {
                            return platformLogoMap[placementPlatform[0].toLowerCase()] || platformLogoMap[platform.toLowerCase()]
                        }
                }

                return platformLogoMap[platform.toLowerCase()]
            },
            showLinkOptions(element) {
                this.hideLinkOptions()
                this.$refs[`${element}-popup`].classList.toggle('show')
            },
            hideLinkOptions() {
                Object.keys(this.$refs).forEach(v => {
                   this.$refs[v].classList.remove('show')
                })
            },
            viewLink(link) {
                window.open(link, "LatestVersion", "directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,width=4000,height=4000");
            },
            deliverableStatusList(selectedStatus) {
                const status = {
                    'admin': ['Submitted', 'Assigned', 'In Production', 'Ready for Internal Review', 'Storyboard - In Progress', 'Storyboard - Ready for Internal Review',
                                'Storyboard - Ready for Review', 'Latest Version Ready', 'Amendments Needed', 'Approved'],
                    'user': ['Amendments Needed'],
                    'editor': ['In Production', 'Ready for Internal Review'],
                    'creative director': ['Storyboard - In Progress', 'Storyboard - Ready for Internal Review']
                }
                
                if (this.isStakeholder) {
                    status.user.push('Approved')
                }

                const roleStatus = status[this.userRole?.toLowerCase()].map(v => ({
                    name: v,
                    $isDisabled: v == selectedStatus ? true : this.isAdmin ? false : !this.statusEnabled(v, selectedStatus)
                }))


                return roleStatus
            },
            statusEnabled(currentStatus, selectedStatus) {
                const statusMap = {
                    'submitted': ['Submitted'],
                    'assigned': ['Submitted', 'Amendments Needed'],
                    'in production': ['Assigned', 'Amendments Needed'],
                    'storyboard - in progress': ['Assigned', 'Amendments Needed'],
                    'ready for internal review': ['In Production'],
                    'storyboard - ready for internal review': ['Storyboard - In Progress'],
                    'storyboard - ready for review': ['Storyboard - Ready for Internal Review'],
                    'latest version ready': ['Sent for Review', 'Storyboard - Ready for Internal Review', 'Amendments Needed'],
                    'amendments needed': ['Storyboard - Ready for Review', 'Latest Version Ready'],
                    'approved': ['Storyboard - Ready for Review', 'Latest Version Ready', 'Amendments Needed']
                }

                const currentStatusMap = statusMap[currentStatus.toLowerCase()]
                return currentStatusMap.includes(selectedStatus)
            },
            handleAddDeliverable() {
                this.projectDialogKey = v4()
                this.showProjectDialog = true 
                document.body.classList.add("modal-open");
            },
            handleToggleProjectDialog() {
                this.showProjectDialog = false
                document.body.classList.remove("modal-open");
                this.handleTableChange()
            },
            formatKeyMessaging(keyMessaging) {
                if (!keyMessaging.includes('--and--')) {
                    return `<span class="form-control-label">${keyMessaging}</span>`
                }
                
                return keyMessaging.split('--and--').map((v, i) => `Key Messaging for Image ${++i}: <span class="form-control-label">${v}</span>`).join('<br><br>')
            },
            async deleteDeliverable(id) {
                if(!confirm(`Are you sure to delete this deliverable?`)) {
                    return
                }

                this.loading = true
                await this.$store.dispatch("REQUEST/deleteDeliverable", id)
                this.handleTableChange()
                if (this.$route?.hash) {
                    this.$router.push({ name: 'request details'})
                }
            }
        },
        computed: {
            ...mapGetters("AUTH", [
                'isUser',
                'isAdmin',
                'userRole'
            ]),
            ...mapState("AUTH", {
                loggedInUser: (state) => state.user 
            }),
            ...mapState("REQUEST", {
                records: function(state) {     
                    return state.deliverableList.map(v => ({
                        id: v.id,
                        created_date: v.created_date,
                        platform: v.properties.platform,
                        placement: v.properties.placement,
                        status: this.isUser && v.status.toLowerCase().includes('internal') ? 'In Progress' : v.status,
                        type: v.properties.type,
                        length: v.properties.length,
                        dimension: v.properties.dimension,
                        keyMessaging: v.properties.keyMessaging,
                        CTA: v.properties.CTA,
                        notes: v.properties.notes,
                        index: v.properties.index || '',
                        reviewLink: v.properties.reviewLink || null
                    }))
                },
                total: (state) =>  state.deliverableListTotal,
                request: (state) => state.request
            }),
            debounceSetTableLonger() {
                return debounce(this.handleTableChange, 700);
            },
            debounceSetTable() {
                return debounce(this.handleTableChange, 250);
            },
            size: {
                get() {
                    return this.table.size;
                },
                set(size) {
                    this.table.size = size;   
                   
                    this.table.page = 0;
                    this.table.size = size;
                    this.debounceSetTable();
                },
            },
            sort: {
                get() {
                    return this.table.sort[0]
                },
                set(sort) {
                    this.table.sort = [sort]
                    this.debounceSetTableLonger()
                }
            },
            pagination: {
                get() {
                    return parseInt(this.table.page) + 1;
                },
                set(page) {
                    this.table.page = parseInt(page) - 1;
                    this.debounceSetTable();
                },
            },
            deliverableTypeList() {
                return [
                    '[All]',
                    'Video',
                    'GIF',
                    'Static'
                ]
            },
            deliverableStatusListFilter() {
                let statuses = ['[All]', 'Submitted', 'Assigned', 'In Production', 'Ready for Internal Review', 'Storyboard - In Progress', 'Storyboard - Ready for Internal Review', 
                                'Storyboard - Ready for Review', 'Latest Version Ready', 'Amendments Needed', 'Approved']
                
                if (this.isUser) {
                    statuses = statuses.filter(v => !v.includes('Internal'))
                }
                 
                return statuses
                    .map((each, i) => (
                        { name: each, id: i }  
                    ));
            },
            isStakeholder() {
                return this.request?.properties?.stakeholder.id == this.loggedInUser?.id
            },
            requestID() {
                return this.$route.params.id
            },
            reviewLinkCTA() {
                if (this.selectedDeliverable && this.selectedDeliverable.status.name.toLowerCase() == 'storyboard - ready for internal review') {
                    return 'Storyboard Review Link'
                } 
                return 'Review Link'
            }
        },

    };
</script>
<style scoped>
    .show-deliverable-section .col {
        display: grid
    }
    
    .long-text {
        white-space: normal;
        word-break: break-word;
    }

    .table .wrap-cell {
        white-space: pre-wrap; 
        word-wrap: break-word
    }

    .popup {
        position: relative;
        display: inline-block;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* The actual popup */
    .popup .options {
        visibility: hidden;
        /* width: 160px; */
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 7px 12px;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -70px;
    }

    /* Popup arrow */
    .popup .options::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
    }

    /* Toggle this class - hide and show the popup */
    .popup .show {
        visibility: visible;
        -webkit-animation: fadeIn 1s;
        animation: fadeIn 1s;
    }

    /* Add animation (fade in the popup) */
    @-webkit-keyframes fadeIn {
        from {opacity: 0;} 
        to {opacity: 1;}
    }

    @keyframes fadeIn {
        from {opacity: 0;}
        to {opacity:1 ;}
    }
</style>
<style>
    .deliverables-container .multiselect {
        width: 10.5vw
    }

    .deliverables-container .multiselect__single,
    .deliverables-container .multiselect__input,
    .deliverables-container .multiselect__element {
        font-size: 13px !important;
    }

    .deliverables-container .table th, .deliverables-container .table td {
        padding: 1.5em 1em !important;
    }

    .deliverables-container .table th {
        text-align: center !important;
    }

    .deliverables-container .table-container {
        /* overflow-x: scroll; */
        overflow-y: visible;
        /* max-height: 100vh; */
    }

    .deliverables-container .table-container::-webkit-scrollbar {
        display: none;
    }

    .deliverables-container .table-container thead tr {
        position: sticky; 
        top: 0px;
        z-index: 1
    }

    .deliverables-container .update-status-container .multiselect__single {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 30em; 
    }
</style>