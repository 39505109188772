<template>
    <div class="card shadow side-tabs-container mt-4" ref="uploaded" :class="{'highlight':uploadHighlight}">
        <div class="card-header bg-transparent upload-header">
            <div class="row align-items-center">
            <div class="col">
                <h3 class="mb-0">{{ pending ? `Uploaded Assets` : `Uploads` }}</h3>
            </div>
            </div>
            <br>
            <div class='row align-items-center'>
                <div v-if="pending" class="col ">
                    <base-button
                        class="mt-2"
                        type="secondary"
                        size="sm"
                        @click.prevent="handleAskDialog"

                    >
                   {{ !isDownloading ? 'Download All' : 'Downloading...' }}
                    </base-button>

                    <base-button 
                        type="primary" 
                        size="sm"
                        class="btn-purple mt-2"
                        @click.prevent="handleDialogToggle"
                    >
                    Upload Files
                    </base-button>

                    <!-- <base-button 
                        type="success" 
                        size="sm"
                        @click.prevent="handleAddFolderDialogToggle"
                    >
                    Add New Folder
                    </base-button> -->
                </div>
            </div>
        </div>
        <!-- <div class="card-header bg-transparent">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">Uploads</h3>
                </div>
                <div class="col text-right">
                    <base-button
                        size="sm"
                        type="primary"
                        class="btn-radius btn-upload-file"
                        @click.prevent="handleDialogToggle(true)"
                    >
                        Upload New File
                    </base-button>

                    <base-button
                        size="sm"
                        type="secondary"
                        class="btn-radius btn-upload-file"
                        @click.prevent="handleDialogToggle(true)"
                    >
                        Add Folder
                    </base-button>
                </div>
            </div>
        </div> -->
        <div class="card-body">
            <!-- <div class="tabs d-flex" style="border-bottom: 1px solid #E8E8E8;">
                <div class="tab-item" @click.prevent="setActiveTab(0)" :class="{active: tabIndex === 0}">Pending</div>
                <div class="tab-item" @click.prevent="setActiveTab(1)" :class="{active: tabIndex === 1}">Uploaded</div>
            </div> -->
            <FileList :client_id="client_id" :requestId="requestId" resource="request" filterNotCompleted :showUploaded="true" :pending="!pending"/>
        </div>

        <div v-if="showUploadDialog">
            <RequestUpload
            :maxFiles="100"
            :show="showUploadDialog"
            :resource_id="directory" 
            resource="request"
            resource_type="file"
            @toggle="handleDialogToggle"
            :client_id="client_id"
            :batchId="batchId"
            />
        </div>
        <modal :show="showAskDialog" @close="handleAskDialog" type="mini">
            <h3 slot="header" class="modal-title" id="modal-title-default">{{ !isDownloading ? `Download all the files?` : `Zipping is currently in-progress...`  }}</h3>
            <template slot="footer" >
                <base-button type="link" class="ml-auto btn-radius" @click="handleAskDialog">
                Close
                </base-button>
                <base-button type="purple" class="btn-radius" @click.prevent="handleDownloadWithLambda" v-if="!isDownloading">
                Download
                </base-button>
            </template>
           
        </modal>
     
        <modal :show="showAddFolderDialog" @close="handleAddFolderDialogToggle" type="mini">
            <h3 slot="header" class="modal-title" id="modal-title-default">Add New Folder</h3>
            <div>
                <base-input
                    required
                    autoresize
                    label="Folder Name"
                    v-model="fields.name"
                    :error="errors.name"
                    :valid="!errors.name ? true : false"
                    @input="validate('name')"
                />
            </div>
            <template slot="footer">
                <base-button type="link" class="ml-auto btn-radius" @click="handleAddFolderDialogToggle">
                Close
                </base-button>
                <base-button type="purple" class="btn-radius" @click.prevent="handleAddFolder">
                Add
                </base-button>
            </template>
        </modal>
    </div>
</template>
<script>
    import {  createAddFolderValidation, createAddFolderInputValidation } from '@/container/request-details/model.js';
    import { mapState } from "vuex"
    import FileList from '@/container/upload/components/FileList'
    import { RequestUpload } from '@/container/request-details/component'
    import cloneDeep from 'lodash/cloneDeep'
    import { v4 } from 'uuid'
    import DownloadDocument from '@/utils/download';

    export default {
        name: 'request-deliverable-section',
        components: {
            FileList,
            RequestUpload
        },
        props: {
            pending: {
                type: Boolean,
                default: false,
                description: 'only display in-progress or pending documents'
            },
        },
        data: () => ({
            tabIndex: 0,
            batchId: '',
            showUploadDialog: false,
            showAddFolderDialog: false,
            showAskDialog: false,
            fields: {
                name: '',
            },
            errors: {
                name: '',
            },
            isDownloading: false,
            uploadHighlight: false
        }),
        created(){
            this.$root.$refs.Delivarable = this;
        },
        computed: {
            ...mapState('REQUEST', {
                request: state => state.request,
            }),
            ...mapState('DOCUMENT', {
                request_documents: state => state.documents,
                uploaded_documents: state => state.data,
                current_directory: state => state.directory,
            }),
            client_id() {
                return this?.request?.client_id || ''
            },
            requestId() {
                return this?.request?.id || this.$route.params.id
            },
            documents() {
                return this.$lodash.values(cloneDeep(this.request_documents))
            },
            directory(){
                return this.current_directory.length <= 0 ? this.requestId : this.current_directory
            },
        },
        methods: {
            setActiveTab(tabIndex = 0) {
                this.tabIndex = tabIndex
            },
            handleDialogToggle(show) {
                this.showUploadDialog = !this.showUploadDialog

                if (show)
                    this.batchId = v4()
            },
            handleAskDialog(){
                this.showAskDialog = !this.showAskDialog
            },
            handleClearQuestionForm() {
                this.fields = {};
                this.errors = {};
                this.showAddFolderDialog = false;
            },
            validate(field) {
                this.errors = createAddFolderInputValidation(field, this.fields, this.errors);
            },
            handleAddFolderDialogToggle(){
                if (this.showAddFolderDialog) {
                    this.fields = {};
                    this.errors = {};
                }

                this.showAddFolderDialog = !this.showAddFolderDialog
            },
            async handleAddFolder(){
                const { isValid, errors } = createAddFolderValidation(this.fields);
                if (!isValid)
                    return this.errors = errors;
               
                let key = `${this.directory}/${this.fields.name}`
                
                 await this.$store.dispatch('DOCUMENT/createS3Folder', key);
                this.handleAddFolderDialogToggle()
                // this.$store.commit('DOCUMENT/setDocuments', this.$lodash.keyBy(files, 'id'));
            },
            async handleDownloadWithLambda() {
                this.showAskDialog = false;
                this.isDownloading = true;
                new DownloadDocument(this.directory, {
                    directDownload: false,
                    file: (f) => {
                        window.open(f)
                        this.isDownloading = false;
                    }
                });
            },
            getProgressType(type, file) {
                const name = this.getFileName(file)
                if ((type || name).includes('word'))
                    return 'primary'

                if (name.includes('xlsx'))
                    return 'success'

                if ((type || name).includes('image') || (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(name))
                    return 'pink'

                return 'green'
            },
            getIcon(type, file) {
                const name = this.getFileName(file)
                if ((type || name).includes('word'))
                    return 'gdoc.svg'

                if (name.includes('xlsx'))
                    return 'gsheet.svg'

                if ((type || name).includes('image') || (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(name))
                    return 'image.svg'

                return 'glink.svg'
            },
            getFileName(file_doc) {
                return file_doc?.file?.name || file_doc?.item?.name || ''
            },
            getDropdownDirection(index) {
                return this.documents.length === index + 1 && this.documents.length >= 3 ? 'up' : 'down'
            },
        },
    }
</script>

<style scoped lang="scss">
    .card-body {
        position: relative;
    }

    .tabs {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-transform: capitalize;
        padding-bottom: 10px;

        .tab-item {
            margin-right: 32px;
            cursor: pointer;
            padding-bottom: 10px;

            &.active {
                font-weight: bold;
                color: #191919;
            }

            &:hover:not(.active) {
                color: #0F3A90;
            }
        }
    }

    .btn-upload-file {
        position: absolute;
        width: 88%;
        margin: 0 auto;
        left: 50%;
        transform: translateX(-50%);
        bottom: 24px;
    }
</style>