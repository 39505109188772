<template>
    <div class="card shadow request-container mt-4">
        <div class="card-header bg-transparent" role='button'  @click="toggle">
            <div class="row align-items-center">
                <div class="col ">
                    <h3 class="mb-0">Order Details</h3>
                    
                </div>
                <div class="col text-right">
                    <span class="fa "  @click="toggle" :class="{ 'fa-eye-slash': showSection, 'fa-eye': !showSection }" role='button'></span>
                   
                </div>
            </div>
        </div>
            <div v-show="showSection">
                <div class="card-body ">
                    <request-input
                        v-model="fields.name"
                        label="Order Title"
                        placeholder="Answer here"
                        :error="errors.name"
                        :valid="!errors.name ? true : false"
                        @input="validate('name')"
                        :readOnly="!isAdmin"
                        @keyup="handleChange"
                    />
                    <request-input
                        label="Project Overview"
                        placeholder="E.g. A collection of Meta, PMAX and Amazon videos for our Spring launch."
                        v-model="fields.description"
                        :error="errors.description"
                        :valid="!errors.description ? true : false"
                        @input="validate('description')"
                        :readOnly="!isAdmin"
                        @keyup="handleChange"
                    />

                    <template v-if="deliverables.length">
                        <div class="type mb-2 request-input">
                            <span>
                                Is this paid or organic?
                            </span>
                            <div class="row my-4">
                                <base-radio name="paid" v-model="fields.type" @input="handleChange(); validate('type')" :disabled="!isAdmin">Paid</base-radio>
                                <base-radio name="organic" v-model="fields.type" @input="handleChange(); validate('type')" :disabled="!isAdmin">Organic</base-radio>
                            </div>
                        </div>

                        <request-input
                            label="Any notes?"
                            placeholder="Please give a short description of the project. For example who is the intended audience and what are the goals of the campaign?"
                            v-model="fields.notes"
                            :error="errors.notes"
                            :valid="!errors.notes ? true : false"
                            @input="validate('description')"
                            :readOnly="!isAdmin"
                            @keyup="handleChange"
                        />
                    </template>

                    <!-- previous projects / orders using questions and no deliverables feature -->
                    <template v-else>
                        <request-input  
                            v-show="showDefaultQuestion"
                            v-for="(each, index) in answers"
                            :key="index"
                            :value="each.answer"
                            :label="each.question"
                            :comment="each.comment"
                            :data-custom=false
                            placeholder="Answer here.."
                            @keyup="handleAnswerChange(index, $event,false)"
                            :readOnly="!isAdmin"
                            :error="each.error"
                        />
                        <request-input
                            :show="(custom_answers.length > 0) ? true:false"
                            v-for="(each, index) in custom_answers"
                            :key="index"
                            :value="each.answer"
                            :label="each.question"
                            :comment="each.comment"
                            :data-custom=true
                            placeholder="Answer here.."
                            @keyup="handleAnswerChange(index, $event,true)"
                            :readOnly="!isAdmin"
                            :error="each.error"
                        />
                    </template>
                </div>
            </div>
        <template v-if="isAdmin && hasChanges">
            <div class="card-footer d-flex justify-content-end bg-transparent">
                <base-button type="link" class="ml-auto btn-radius" @click="handleCancel">Cancel</base-button>
                <base-button type="purple" class="btn-radius" @click.prevent="handleCreateRequest">Save changes</base-button>
            </div>
        </template>
    </div>
</template>
<script>
    import { mapState, mapGetters  } from "vuex";
    import { RequestInput } from "@/container/request-details/component";
    import {
        requestInitialState,
        createRequestValidation,
        createRequestInputValidation,
    } from "@/container/request-details/model";
    import NOTIFICATION_TITLE from '@/utils/constant'
    // import { VBTogglePlugin } from 'bootstrap-vue'
    // Vue.use(VBTogglePlugin)
    export default {
        name: "request-section",
        components: {
            RequestInput,
        },
        props: {
            isNew: {
                type: Boolean,
                description: "to determine if new request or not",
            },
        },
        data () {
            return {
                showSection: false,
                hasChanges: false,
                showCustomQuestion: false,
                showDefaultQuestion: true,
                answers: [],
                custom_answers: [],
                refAnswers: [],
                refCAnswers: [],
                refFields: {},
                fields: {
                    name: "",
                    description: "",
                    type: "paid",
                    notes: ""
                },
                errors: {
                    name: "",
                    description: "",
                },
            }
        },
        methods: {
            toggle() {
                this.showSection = !this.showSection
            },
            validate(field) {
                this.errors = createRequestInputValidation(field, this.fields, this.errors);
            },
            handleChange() {
                if (!this.$lodash.isEqual(this.refFields, this.fields))
                    return this.hasChanges = true

                if (!this.$lodash.isEqual(this.refAnswers, this.answers))
                    return this.hasChanges = true
                
                if (!this.$lodash.isEqual(this.refCAnswers, this.custom_answers))
                    return this.hasChanges = true

                return this.hasChanges = false
            },
            handleClearRequestForm() {
                this.fields = {};
                this.errors = {};
            },
            handleAnswerChange(index, event,custom) {
                const { value } = event.target
                if(custom){
                    this.custom_answers[index].answer = value
                }else{
                     this.answers[index].answer = value
                }
                
                this.handleChange()
            },
            handleCancel() {
                this.answers = JSON.parse(JSON.stringify(this.refAnswers))
                this.fields  = JSON.parse(JSON.stringify(this.refFields))
                this.hasChanges = false
            },
            async handleGetDetails() {
                if (this.requestId == 'new')
                    return

                await this.$store
                    .dispatch('REQUEST/getRequestData', this.requestId)
                    .catch(() => this.$router.push({ name: 'request'}))
            },
            async handleGetQuestions() {
                this.$store.dispatch("REQUEST/getRequestCustomQuestions", this.requestId)
                this.$store.dispatch("REQUEST/getRequestQuestions", this.requestId);                
            },
            async handleSelectClient(clientId){
                this.$store.dispatch("REQUEST/getClientCustomQuestions", clientId);
               
            },
            async handleCreateRequest() {
                let request
                // make sure only admin can edit
                if (this.$lodash.lowerCase(this.requestId) !== 'new' && !this.isAdmin)
                    return

                const { isValid, errors } = createRequestValidation(this.fields)

                // v3
                if (this.deliverables.length) {
                    const data = this.data || {}
                    const { id } = data

                    await this.$store.dispatch("REQUEST/updateRequestV3", {
                        id,
                        name: this.fields.name,
                        description: this.fields.description,
                        properties: {
                            type: this.fields.type,
                            notes: this.fields.notes
                        }
                    });
                    
                    this.$notify({
                        icon: "ni ni-check-bold",
                        type: "success",
                        title: "Success",
                        message: `Order successfully updated`,
                    })

                    return
                }

                // previous projects / orders using questions and no deliverables feature
                this.answers = this.answers.map(each => {
                    if (each?.required && !each?.answer)
                        return { ...each, error: 'this is a required field' }
                    return { ...each, error: null }
                
                })
                this.custom_answers = this.custom_answers.map(each => {
                    if (each?.required && !each?.answer)
                        return { ...each, error: 'this is a required field' }
                    return { ...each, error: null }
                
                })
                if (!isValid)
                    return (this.errors = errors)

                // if (this.answers.some(({ error }) => error))
                //     return
                // if (this.custom_answers.some(({ error }) => error))
                //     return

                if (this.requestId === 'new') {
                    try {
                        request = await this.$store.dispatch("REQUEST/createRequest", {
                            ...(this.data || {}),
                            ...this.fields,
                            answers: this.answers,
                            custom_answers: this.custom_answers
                        });
                        this.$router.push(`/request/${request.id}`)
                        
                        if(request) {
                            request.members.forEach(d => {
                                if(d != this.user.id) {
                                    this.$socket.send(JSON.stringify({
                                        recipient: d,
                                        title: NOTIFICATION_TITLE.NEW_BRIEF_SUBMITTED,
                                        message: `${this.user.first_name} ${this.user.last_name} submitted ${request.name} and  added you as a collaborator`
                                    }));
                                }
                            });
                        }

                    } catch (err) {
                        console.log('err', err)
                    }
                } else {
                    try {
                        request = await this.$store.dispatch("REQUEST/updateRequest", {
                            id: this.data.id,
                            ...this.fields,
                            answers: this.answers,
                            custom_answers: this.custom_answers
                        })
                        
                    } catch(err) {/**/}
                }

                if (this.requestId === 'new' && request)
                    this.handleClearRequestForm()

                if (this.requestId !== 'new' && request)
                    this.handleGetDetails()

                if (request) {
                    this.$notify({
                        icon: "ni ni-check-bold",
                        type: "success",
                        title: "Success",
                        message: `Request successfully ${this.requestId === 'new'?'created':'updated'}!`,
                    })
                } else {
                    // this.$notify({
                    //     icon: "fa fa-times",
                    //     type: "danger",
                    //     title: "Error",
                    //     message: `Something went wrong!`,
                    // })
                }
            },
        },
        beforeDestroy() {
            Object.assign(this.$data, requestInitialState());
        },
        created() {
           
            this.$root.$refs.request = this;
            this.handleGetQuestions();
        },
        computed: {
            ...mapGetters("AUTH", [
                'isAdmin',
            ]),
            ...mapState('AUTH', {
                user: state => state.user,
            }),
            ...mapState('CLIENT', {
                custom_questions: (state) => state.custom_questions,
            }),
            ...mapState("REQUEST", {
                data: (state) => state.request,
                deliverables: (state) => state.request.deliverables || [],
                questions: (state) => state.questions,
                custom_questions: (state) => state.custom_questions,
            }),
            requestId() {
                return this.$route.params.id
            },
            
            // showCustomQuestion() {
            //     if (!this.custom_answers.length && latest.length) {
            //        return true;                   
            //     }
            //     return false;
            // }
        },

        watch: {
            
            questions(latest) {
                if (!this.answers.length && latest.length) {
                    this.answers = JSON.parse(JSON.stringify(latest))
                    this.refAnswers = JSON.parse(JSON.stringify(latest))
                }
               
            },
            custom_questions(latest){
               if (!this.custom_answers.length && latest.length) {
                    this.custom_answers = JSON.parse(JSON.stringify(latest))
                    this.refCAnswers = JSON.parse(JSON.stringify(latest))
                    
                    this.showDefaultQuestion = false;
                
                }else{
                    this.custom_answers = []
                    this.refCAnswers = []
                     this.showDefaultQuestion = true;
                }
                console.log("CUSTOMQUESTION",this.custom_questions)
                
            },
            data(latest) {
                if (!latest?.id)
                    return

                this.fields = this.$lodash.pick(latest, ['name', 'description'])
                if (latest.properties) {
                    const { type, notes } = latest.properties
                    this.fields.type = type
                    this.fields.notes = notes
                }

                this.refFields = this.$lodash.pick(latest, ['name', 'description'])
                this.hasChanges = false
            }
        }
    };
</script>
<style>
    .card-body .type span{
        font-size: 14px;
        color: black;
    }

    .card-body .type .custom-control-label::before {
        position: unset !important;
        margin-right: 1em !important;
    }

    .card-body .type .custom-control-label::after {
        background: unset !important;
    }

    .card-body .type .custom-control-label {
        display: flex !important;
    }

    .card-body .type .custom-control {
        padding-left: 1vw !important;
    }
</style>