// import isNaN from 'lodash/isNaN';
import { v4 } from 'uuid';
import Vue from 'vue';
import axios from '@/utils/axios'
// import bytes from 'bytesish'
import streamSaver from 'streamsaver'
import * as ponyfill from 'web-streams-polyfill/ponyfill'
streamSaver.WritableStream = ponyfill.WritableStream


const DEFAULT_OPTIONS = {
    onError: () => null,
    onSuccess: () => null,
    onCancel: () => null,
    onUpdatePercentage: () => null,
    file: () => null,
  };

export default class DownloadDocument {
    constructor(key, options = {}) {
        this.key = key
        this.options = {
            id: v4(),
            ...DEFAULT_OPTIONS,
            ...options
          };
        this.init();
    }

    async init() {    
        const directDownload = this.options?.directDownload 
        if(directDownload) {
            this.directDownload();
        } else {
            this.zipThenDownload()
        }
    }

    async directDownload() {
        const key = this.key;
        // const fileName = key.split('/').pop();
        const p = key.split("/");
        const _key = key.replace(p[p.length - 1], encodeURIComponent(p[p.length - 1]))
        console.log('key', _key);
        const signedURL = await this.getS3SignedURL(_key);

        this.options?.file(signedURL)
        // this.runStreamSaver(signedURL, fileName);
    }

    async zipThenDownload() {
        console.log(`Currently zipping the directory...`, 'Please wait');
        this.notification(`Currently zipping the directory...`, 'Please wait');
        const uploadedData = await this.zipDirectoryContent();
    
        if(uploadedData) {
             if(uploadedData.StatusCode === 200){
                 const parsed = JSON.parse(uploadedData.Payload)
                 if(parsed.statusCode === 200){
                    const key = parsed.body.Key;
                    const fileName = key.split('/').pop();
                    console.log(`Done zipping. Zip file: ${fileName}`, `Preparing for download...`);
                    this.notification(`Done zipping. Zip file: ${fileName}`, `Preparing for download...`);
                    const signedURL = await this.getS3SignedURL(key);
                    this.options?.file(signedURL)
                    // this.runStreamSaver(signedURL, fileName);
                 }
             }
         }
    }

    async getS3SignedURL(key) {
        const response = await axios.request({
            url: `/s3/asset-object?key=${key}`,
            method: 'GET'
        })

        return response.result;
    }

    runStreamSaver(signedURL, fileName) {
        const fileStream = streamSaver.createWriteStream(fileName)

        fetch(signedURL).then(res => {
            console.log(`Currently downloading... ${fileName}`, 'Please wait');
            this.notification(`Currently downloading... ${fileName}`, 'Please wait');
            
            const readableStream = res.body

            // more optimized
            if (window.WritableStream && readableStream.pipeTo) {
                return readableStream.pipeTo(fileStream)
                .then(() => console.log('done writing'))
            }
            const writer = fileStream.getWriter()

            const reader = res.body.getReader()
            const pump = () => reader.read()
                // .then(res => res.done
                //   ? writer.close()
                //   : writer.write(res.value).then(pump))
                .then(res => {
                    if(res.done) {
                        console.log('Done downloading!', fileName);
                        this.notification('Done downloading!', fileName);
                        this.options?.file(signedURL)
                        writer.close();
                    } else {
                        writer.write(res.value).then(pump)
                    }
                })

            pump()
        })
    }

    async zipDirectoryContent() {
        try {
            this.notification('Zipping Files', this.key);
            const response = await axios.request({
                url: `/s3/zip?key=${this.key}`,
                method: 'GET'
            })
            if(response) {
                this.notification('Files Zipped!', 'Preparing for download...');
            }
            return response;
        } catch (error) {
            this.notification('Error: Zipping Files', error, true);
            console.log('error', error)
        }
    }

    async sizeOf(key) {
        try {
            const response = await axios.request({
                url: `/s3/file-size?key=${key}`,
                method: 'GET'
            })

            return response 
        } catch (error) {
            this.notification('Error Getting Size Of', key)
            console.log('error', error);
        }
    }

    notification(title, message, error = false){
        let icon = 'fa fa-info-circle'
        let type = 'success'
        if (error) {
            icon = 'fa fa-exclamation-triangle'
            type = 'danger'
        }

        Vue.prototype.$notify({
            icon: icon,
            type: type,
            title: title,
            message: message
        });
    }

    // with progress watcher, for future use
    async runPromises(proms, progress_cb, callback) {
        let d = 0;
        progress_cb(0);
        for (const p of proms) {
          p.then(()=> {    
            d ++;
            progress_cb( (d * 100) / proms.length );
          });
        }
        Promise.all(proms).then((values) => callback(values));
    }
}