<template>
  <div class="request-input">
    <slot>
      <base-input
        autoresize
        textarea
        :placeholder="placeholder"
        :class="{
          'hide-textarea': !open
        }"
        :value="value"
        v-on="listeners"
        v-bind="$attrs"
        @keydown.tab="onTab"
      >
        <div slot="label" class="label-container d-flex justify-content-between align-items-center" @click.prevent="toggle">
          <label  class="form-control-label">
            <span>{{ label }}</span>
            <small class="d-block" v-if="comment">{{ comment }}</small>
          </label>
          <div slot="label-icon" v-if="!stayOpen">
            <img :src="`/img/icons/common/arrow-${!open ? 'up' : 'down'}.svg`" class="image-icon" />
          </div>
        </div>
      </base-input>
    </slot>
  </div>
</template>
<script>
  export default {
    name: 'request-input',
    props: {
      label: {
        type: String,
        description: "Input label (text before input)",
      },
      comment: {
        type: String,
        description: "Secondary label",
      },
      placeholder: {
        type: String,
        description: "Input placeholder (text before input)",
      },
      stayOpen: {
        type: Boolean,
        default: true,
        description: "to show or hide the input field",
      },
      show: {
        type: Boolean,
        default: false,
        description: "to show or hide the input field",
      },
      value: {
        type: String,
        description: "Input value (text before input)",
      }
    },
    data: () => ({
      isOpen: true
    }),
    methods: {
      toggle() {
        if (this.stayOpen)
          return
        this.open = !this.open
      },
      updateValue(value) {
        this.$emit("input", value);
      },
      onFocus(value) {
        if (!this.stayOpen)
          this.open = true

        this.$emit("focus", value);
      },
      onBlur(value) {
        this.$emit("blur", value);
      },
      onTab(value) {
        if (!this.stayOpen)
          setTimeout(() => this.open = false, 150)

        this.$emit("tab", value);
      }
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          input: this.updateValue,
          focus: this.onFocus,
          blur: this.onBlur,
        };
      },
      open: {
        get() {
          return (this.show && this.isOpen === null)
            ? this.show
            : (this.isOpen !== null)
            ? this.isOpen
            : false
        },

        set(value) {
          this.isOpen = value
        }
      }
    }
  }
</script>