 
<template>
  <div class="card shadow messsage-container mt-4">
    <div class="card-body">
      <div class="message-input">
          <!-- <at-ta :members="items"> -->
            <!-- <base-input
              textarea
              autoresize
              inputClasses="upload-comment-input"
              placeholder="New message"
              v-model="message"
            /> -->
          <!-- </at-ta> -->
        <div class="form-group">
          <!-- <slot> -->
          <at-ta ref="at" v-model="text" :members="items" name-key="value" :allowSpaces="false" >
            <textarea
              class="form-control upload-comment-input"
              v-bind="$attrs" 
              aria-describedby="addon-right addon-left"
              v-model="message"
              placeholder="Leave a comment here"
            />
          </at-ta>
      <!-- </slot> -->
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 upload-options-container d-none">
          <base-button
              type="neutral"
              rounded
              @click="handleCreate"
          >
            <img src="/img/icons/common/clip.svg" class="image-icon" />
          </base-button>
          <base-button
              type="neutral"
              rounded
              @click="handleCreate"
          >
            <img src="/img/icons/common/link.svg" class="image-icon" />
          </base-button>
        </div>
        <div class="col-md-12 d-flex justify-content-end align-items-center">
          <div class="col-xs-2">
            <base-button
                block
                type="purple"
                class="btn-radius"
                @click="handleCreate"
            >Send All
            </base-button>
          </div>
        </div>
        <div class="col-md-12 d-flex align-items-center">
          <span style="font-weight: bold; font-size: 12px">Add @ to tag someone in your comment</span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
//import qs from 'qs';
import { mapState } from 'vuex';
import AtTa from 'vue-at/dist/vue-at-textarea'
import NOTIFICATION_TITLE from '@/utils/constant'

export default {
  name: 'request-message-section',
  components: {  AtTa },

  data () {
    return {
      text: '',
      message: '',
      client_id: '',
      items: [],
      notify_users: [],
      members: []
    }
  },
  
  methods: {
    clearMesage() {
      this.message = '';
    },
    async handleCreate() {
      // if (!this.message.trim())
      //   return;
      this.notify_users = [];
      const nodes = this.$refs.at.$el.childNodes;
      const commentText = nodes[2].value
      console.log('commentText', commentText);

      const commentTextArr = commentText.split(" ");
      console.log('commentTextArr', commentTextArr);
      
      commentTextArr.forEach(comment => {
        if(comment.includes('@')) {
          this.items.forEach(item => {
            if(item.value == comment.split('@').pop()) {
              this.notify_users.push(item)
                }
              });
          }
      });
      var newMessage = {
                      message: commentText,
                      request_id: this.request.id,
                      user_id: this.user.id,
                      user_name: `${this.user.first_name} ${this.user.last_name}`,
                      client_id: this.request.client_id,
                      notify_users: this.notify_users,        
                      user: this.user
                    }
      const newArray = [newMessage].concat(this.comments)
      this.$store.commit('REQUEST/setComment', newArray)
      
      this.clearMesage();

      const comment = await this.$store.dispatch('REQUEST/createRequestComment', {
        message: commentText,
        request_id: this.request.id,
        user_id: this.user.id,
        client_id: this.request.client_id,
        notify_users: this.notify_users,
        members: this.request.members,
      });

      
      if (!comment) {
        this.$notify({
          icon: 'fa fa-exclamation-triangle',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`,
        });
      } else {
          // notify mentioned users
          this.notify_users.forEach(d => {
            this.$socket.send(JSON.stringify({
              recipient: d.id,
              title: NOTIFICATION_TITLE.NEW_COMMENT,
              message: `${this.user.first_name} ${this.user.last_name} mentioned you in ${this.request.name}`
            }));
          });

          //notify users that was not mention
          this.request.members.forEach(d => {
              const exists = this.notify_users.filter(a => a.id == d);
          
              if(exists.length == 0 && d != this.user.id) {
                this.$socket.send(JSON.stringify({
                  recipient: d,
                  title: NOTIFICATION_TITLE.NEW_COMMENT,
                  message: `${this.user.first_name} ${this.user.last_name} commented in ${this.request.name}`
                }));
              }              
          });
      }
      this.$root.$refs.request.handleGetDetails();
      //this.$root.$refs.A.getComments();
    },

    
   async getClientMembers() {
      const members = await this.$store.dispatch('CLIENT/getClientMembers', this.client_id)
      
      let arr = [];
      members.forEach(member => {
        if(this.user.role === 'User') {
          if(member.role !== 'Editor') {
            arr.push({
              id: member.id,
              value: `${member.first_name.trim()}${member.last_name.charAt(0).toUpperCase()}`
            })
          }
        } else if(this.user.role === 'Editor')  {
          if(member.role !== 'User') {
            arr.push({
              id: member.id,
              value: `${member.first_name.trim()}${member.last_name.charAt(0).toUpperCase()}`
            })
          }
        } else {
          arr.push({
            id: member.id,
            value: `${member.first_name.trim()}${member.last_name.charAt(0).toUpperCase()}`
          })
        }

      });

      this.items = arr
   },
  },
  computed: {
    ...mapState('REQUEST', {
       request: state => state.request,
       comments: state => state.comments,
       sortDir: state => state.commentSortDirection
    }),
    ...mapState('AUTH', {
      user: state => state.user,
    }),
  },
  watch: {
    request(data) {
        this.client_id = data.client_id
        this.getClientMembers()
    }
  }
};
</script>
<style>
#app .atwho-view { /* more */ }
#app .atwho-ul { /* more */ }
</style>