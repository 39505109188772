<template>
    <div class="card shadow collab-container">
        <div class="card-body" ref='collaborators' :class="{'highlight' : colabHighlight}" >
            <div class="collab-client mb-2 align-items-center" v-if="client_name"
                :style="{'cursor': 'pointer'}" @click="() => { this.$router.push(`/client/${client_id}`) }"
                title="View client details">
                <div class="d-flex align-items-center">
                    <div class="avatar avatar-md rounded-circle mr-0">
                        <img v-if="client_photo" :src="client_photo" />  
                        <span v-else class="collab-member-initial" >
                            {{ $tools.getInitial(client_name) }}
                        </span> 
                    </div>
                </div>
                <div class="collab-client-details mx-3">
                    <a :href="`/client/${client_id}`" class="collab-client-name">{{ client_name }}</a>
                    <div class="collab-client-email d-none">bluebear@gmail.com</div>
                </div>
            </div>
            <div class="row mt-4" v-if="isNew && isAdmin">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-control-label">
                      Client <span class="text-red">*</span>
                    </label>
                    <multiselect
                      v-model="fields.client"
                      :options="clientList"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :searchable="false"
                      placeholder="Select Client"
                      label="name"
                      track-by="id"
                      selectLabel=""
                      deselectLabel=""
                     
                    />
                  </div>
                </div>
            </div>
            <template v-if="(isAdmin && fields.client_id) || !isAdmin || !isNew">
                <span class="collab-label">Collaborators</span>
                <div class="collab-members">
                    <div class="avatar-group flex-wrap px-2">
                        <template>
                            <div
                              
                               class="avatar avatar-md rounded-circle" 
                                v-for="(collaborator, index) in collaborators"
                                :key="index"
                            >
                                <img :src="getPhoto(collaborator)" v-if="getPhoto(collaborator)"  :title="getName(collaborator)" @click="handleDeleteCollab(collaborator)"/>
                                <span class="collab-member-initial" v-if="!getPhoto(collaborator)"  :title="getName(collaborator)" @click="handleDeleteCollab(collaborator)">
                                    {{ $tools.getInitial(getName(collaborator) || collaborator.email) }}
                                </span>
                            </div>
                        </template>
                        <div class="no-user" v-if="!members.length">
                            <img src="/img/icons/common/smile.svg" class="image-icon" />
                        </div>
                        <div v-if="isAdmin || isUser">
                            <base-button
                            type="neutral"
                            rounded
                            @click="handleToggleCollab"
                            v-if="!isUserAndClientIsNotActive"
                            >
                                <img src="/img/icons/common/plus-circle.svg" class="image-icon" />
                        </base-button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="card-body collab-input" v-if="showUsers" >
            <base-input
                addonLeftIcon="ni ni-circle-08"
                placeholder="Name or email"
                v-model="search"
                @focus.prevent="handleFocus"
                @blur="toggleFocus($event)"
            />
            <ul class="dropdown-menu dropdown-menu-right" v-if="keyword || (isFocus && users.length)">
                <a
                    href="#"
                    class="dropdown-item"
                    v-for="({ email, first_name, last_name, id}, index) in users"
                    :key="index"
                    @click.prevent="handleAddCollab(users[index])"
                >
                    <i class="fa fa-user-plus text-gray"/>
                    <div class="dropdown-user">
                        <span class="dropdown-user-name">
                            {{ $tools.capitalize(first_name) }} {{ $tools.capitalize(last_name) }}
                        </span>
                        <small class="text-purple">{{ email }}</small>
                       
                    </div>
                </a>
                <template v-if="!users.length && !shouldInviteUser">
                    <a href="#" class="dropdown-item text-gray disabled" disabled>
                        <i class="ni ni-fat-remove"/>
                        <span class="text-gray">No results found</span>
                    </a>
                </template>
                <template v-if="shouldInviteUser">
                    <a href="#" class="dropdown-item text-purple" @click.prevent="handleSendInvite">
                        <i class="ni ni-fat-add"/>
                        <span class="text-purple">Invite '{{ keyword }}' via email</span>
                    </a>
                </template>
            </ul>
        </div>
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex"
    import debounce from 'lodash/debounce'
    import upperFirst from 'lodash/upperFirst'
    import Multiselect from 'vue-multiselect'
    import qs from "qs";

    export default {
        name: 'request-collaborator-section',
        components: {
            Multiselect
        },
        data: () => ({
            keyword: '',
            isFocus: false,
            showUsers: false,
            fields: {
                client_id: '',
                client: null
            },
            hover_index: -1,
            base64Image: null, 
            colabHighlight:false 
        }),
        methods: {
            toggleFocus(event) {
                if (event?.relatedTarget?.classList?.contains('dropdown-item'))
                    return

                this.isFocus = !this.isFocus
            },
            handleFocus() {
                this.handleSearchChange()
                this.toggleFocus()
            },
            handleToggleCollab() {
                this.showUsers = !this.showUsers
            },
            handleAddCollabLocal(user) {
                this.$store.commit('REQUEST/setRequestCollaborator', user)
            },
            handleResetRequest(user) {
                this.$store.commit('REQUEST/resetState', user)
            },
            async handleDeleteCollab(user) {
                console.log('user', user)
                const name = user.name

                if(confirm(`Are you sure to remove ${name}?`)) {
                    const user_details = await this.$store.dispatch('USER/getUserDataByEmailNoCommit', user.email);
                    
                    this.$store.dispatch('REQUEST/updateCollaborator', {
                            id: this.requestId,
                            user_id: user_details.id,
                            action: 'delete'
                    })
    
                    this.$notify({
                        icon: "ni ni-check-bold",
                        type: "success",
                        title: "Success",
                        message: `${name} successfully removed as collaborator`,
                    })
                }
            },
            handleAddCollab(user) {
                const name = `${this.$lodash.upperFirst(user.fname)} ${this.$lodash.upperFirst(user.lname)}`
                this.toggleFocus()
                this.handleToggleCollab()

                if (this.isNew)
                    this.handleAddCollabLocal(user)
                else {
                    this.$store.dispatch('REQUEST/updateCollaborator', {
                        id: this.requestId,
                        user_id: user.id,
                    })
                }

                this.$notify({
                    icon: "ni ni-check-bold",
                    type: "success",
                    title: "Success",
                    message: `${name} successfully added as collaborator`,
                })
            },
            async handleSendInvite() {
                if (!this.$tools.validateEmail(this.keyword))
                    return

                this.toggleFocus()
                this.handleToggleCollab()

                const request = await this.$store.dispatch('INVITE/createInvite', {
                    request_ids: [this.requestId],
                    client_id: this.client_id,
                    email: this.keyword
                })

                if (request) {
                    this.$notify({
                        icon: "ni ni-check-bold",
                        type: "success",
                        title: "Success",
                        message: `Invitation sent to ${this.keyword}`,
                    })
                }
            },
            handleSearchChange() {
                const params = qs.stringify({
                    type: 'available',
                    id: this.requestId,
                    keyword: this.keyword,
                    exclude: this.request?.members,
                    client_id: this?.fields?.client_id
                })
                this.$store.dispatch("REQUEST/getRequestCollaborators", {
                    params,
                    id: this.requestId,
                })
            },
            handleSelectClient(){
                this.$store.dispatch("CLIENT/getClientCustomQuestions", this?.fields?.client_id);
               
            },
            getColors(index) {
                const colors = ['bg-success','bg-purple','bg-warning','bg-info','bg-primary','bg-danger']
                if (!colors[index])
                    return colors[this.$tools.randomNumber(5)]
                return colors[index]
            },
            getName(user) {
                return user?.name || `${user?.first_name} ${user?.last_name}`
            },
            async getClientList() {
                const params = qs.stringify({
                  status: 'Active',
                  fields: ['id', 'name','profile_image']
                })
                await this.$store.dispatch('CLIENT/getAllClientListData', params)
            },
            getPhoto(client) {
                return client.profile_image || null
            },
        },
        computed: {
            ...mapGetters("AUTH", [
                'isUser',
                'isAdmin',
                'isUserAndClientIsNotActive'
            ]),
            ...mapState('REQUEST', {
                request: state => state.request,
                users: state => state.collaborators,
                loading: state => state.loading,
            }),
            ...mapState('CLIENT', {
                clients: state => state.data,
                custom_questions: (state) => state.custom_questions,
            }),
            clientList() {
                return this.clients.map(each => ({
                    ...each,
                    name: upperFirst((each?.name).trim()),
                }))
            },
            client() {
                return this?.fields?.client
            },
            shouldInviteUser() {
                return !this.loading
                    && !this.users.length
                    && !this.isNew
                    && this.keyword
                    && this.$tools.validateEmail(this.keyword)
            },
            client_photo() {
                console.log(this)
                return (
                    this?.request?.client?.profile_image ||
                    this?.client?.profile_image || this.base64Image
                )
            },
            client_name() {
                return (
                    this?.client?.name ||
                    this?.request?.client_name || ''
                )
            },
            client_id() {
                return (
                    this?.request?.client_id ||
                    this?.client?.id || ''
                )
            },
            members() {
                return this.request?.members || []
            },
            collaborators() {
                return this.request?.collaborators || []
            },
            requestId() {
                return this.$route.params.id
            },
            debounceSetTableLonger() {
                return debounce(this.handleSearchChange, 500)
            },
            search: {
                get() {
                    return this.keyword
                },
                set(keyword) {
                    this.keyword = keyword
                    this.debounceSetTableLonger()
                },
            },
            isNew() {
               return this.$lodash.lowerCase(this.requestId) === 'new'
            }
        },
        created() {
            this.$root.$refs.Collaborator = this;
            if(this.isAdmin && this.requestId === 'new') {
                this.getClientList()
            }
            console.log(this);
        },
        watch: {
            client(data) {
                this.fields.client_id = data?.id
                this.handleResetRequest(data?.id)

                if (this.client?.id !== data?.id) {
                    this.handleToggleCollab()
                }
               this.$root.$refs.request.handleSelectClient(this?.fields?.client_id);
            },
        }
    }
</script>