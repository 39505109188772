<template>
  <div class="card shadow comment-container mt-4">
    <div class="px-4 my-2">
      <div class="d-flex justify-content-between py-4 w-100 flex-wrap" :style="{'border-bottom': '1px solid #e0e0e0'}">
        <h3 class="mb-0">Latest Activity</h3>
        <a href="#!" class="b-b-primary text-primary" v-if="total > 2" @click="seeAll()">See All</a> 
      </div>
    </div>
    
    <div class="card-body " v-if="!loading">
      <div class="comment-messages-container activity file-container no-height" >
        <div class="comment-message" v-for="(row, index) in logs" :key="index" >
          <div class="comment-user d-block">
            <div class="d-flex justify-content-around flex-wrap">
              <div class="u-img avatar avatar-md rounded-circle mb-2"> 
                <img  :src="row.profile_image" v-if="row.profile_image">  
                <span class="collab-member-initial" v-if="!row.profile_image">
                  {{ $tools.getInitial(getName(row)) }}
                </span>
              </div>
              <div class="comment-user-details w-75">
                  <div class="comment-name">{{ row.requested_by }}</div>
                  <div class="text-muted comment-date ">set{{ getTableExtensionData(row) }} <span class="font-weight-bold font-italic text-monospace"> {{ row.from }}  </span> to  <span class="font-weight-bold font-italic text-monospace"> {{ row.to }}  </span></div>
                  <div class="text-muted comment-date" >{{$moment(row.created_date).fromNow()}}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!logs.length" class="d-flex justify-content-center">
          <small>No latest activity</small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import qs from 'qs';
import { logs_columns } from '@/container/request-details/model';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import { mapState } from 'vuex';

export default {
  name: 'request-logs-section',
  props: {
    isNew: {
      type: Boolean,
      description: 'to determine if new request or not',
    }
  },
  data () {
    return{
      mentionArray:[],     
      logs_columns: logs_columns,
        table: {
          sort: [
            {
              column: 'created_date',
              direction: 'desc'
            }
          ],
          affected_id: this?.requestId || this.request?.id,
          page: 0,
          size: 4,
          sizeOptions: [
           5, 10, 20, 50
          ],
         
        },
    }
  },
  methods: {
   
   
     handleTableChange() {
       console.log('handleTableChange');
       
         this.$store.dispatch('REQUEST/getRequestLogs', qs.stringify(omit(this.table, ['sizeOptions']))+'&affected_id='+this?.requestId || this.request?.id);
      
      //
    },
    seeAll(){
      this.table.size = 1000;
      this.handleTableChange();
    },
   
    getName(row) {
      return row?.requested_by;
    },
    
    getTableExtensionData(row) {
      switch(row.table.toUpperCase()) {
        case 'DELIVERABLE': {
          const { placement, type, dimension, length } = row.properties
          let { platform } = row.properties
          platform = `${platform} ${placement}`
          if (platform.toLowerCase().includes('facebook / instagram / threads') || platform.toLowerCase().includes('facebook / instagram')) { 
            platform = placement
          }

          return ` ${platform} ${type} ${dimension}${length ? ` ${length}s` : '' } deliverable status from` 
        }
        default:
          return ''
      }
    }
    
  },
  created() {
     this.$root.$refs.RequestLogs = this;
    if (this.request?.request_status !== 'Submitted')
      this.handleTableChange();
  },
  mounted() {

  },
  computed: {
    ...mapState('REQUEST', {
      request: state => state.request,
      logs: state => state.logs,
      total: state => state.total,
      sortDir: state => state.logsSortDirection,
      loading: state => state.loading,
      
    }),
    ...mapState('AUTH', {
      user: state => state.user,
    }),
    debounceSetTableLonger() {
      return debounce(this.handleTableChange, 700);
    },
    debounceSetTable() {
      return debounce(this.handleTableChange, 250);
    },
    requestId() {
      console.log('this.$route.params', this.$route.params)
      return this.$route.params.id;
    },
    startCase: () => startCase,
    
    
   
    
  },
  watch: {

    logs(g) {
     console.log('logs loaded', g)
    }
  }
};
</script>

<style scoped>
.edit-comment > a {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;

  box-shadow: none;
  background: transparent;

  border-radius: 100%;
}

.edit-comment > a:hover {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: #F4F5F7 !important;
}

.highlights__content {
  position: relative;
}
 
.highlights__placeholder {
  color: #ccc;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: -1;
}
 
.highlights__body-container {
  border-radius: 5px;
  border: 1px solid #eaeaea;
  padding: 16px;
}
 
.highlights__body {
  min-height: 60px;
}
 
.highlights {
  color: #ff3b8e;
}

 
.highlight {
  animation: blink 3s ease;
}

.sort {
  height: 25px;
  width: 25px;
}

@keyframes blink {
  0% {
    background-color: #8F64D9;
  }
  100% {
    background-color: white;
  }
}

.editor-highlight {
   background-image: linear-gradient(45deg, yellow, white);
}

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #191919;
  }

  .comment-user-details.wrapped {
    background-color: 'red';
  }

</style>
