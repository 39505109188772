<template>
  <div class="card shadow mt-4" style="cursor: pointer;">
    <div class="card-header no-border bg-transparent"  @click="openPortal">
      <div class="row align-items-center">
        <div class="ml-1" style="width:70% !important">
          <h3 class="mb-0 text-capitalize">View latest version </h3>
        </div>

          <div class=" text-right" style="width:28% !important">
            <img :src="`/favicon-16x16.png`" class="image-icon" />            
        </div>
      </div>
    </div>
  
    
  </div>
</template>

<script>
   import { mapState } from "vuex"
     
    export default {
      name: 'request-review-link',
      data: () => ({
          
          windowRef: null,
      }),
      methods: {
          onLoad(frame) {
              this.myIframe = frame.contentWindow
          },
          openLink() {
            this.isLinkOpen = !this.isLinkOpen;
          },
          openPortal() {
            this.windowRef = window.open(this.requestReviewLink, "LatestVersion", "directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,width=4000,height=4000");
            this.windowRef.addEventListener('beforeunload', this.closePortal);
          }
      },
      created() {
        
      },
     
      computed: {
        ...mapState('REQUEST', {
            data: state => state.request,
        }),
        requestStatus() {
          return this.data && this.data.request_status || ''
        },
        requestReviewLink() {
          return this.data && this.data.review_link || ''
        },
       
      },
      watch: {
        
      }
    };
</script>

<style scoped>
    .review-link {
        height: 500px;
    }

    .card-body-review-link {
        padding: 0%;
    }
</style>
 
